@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?ww4436');
  src:  url('fonts/icomoon.eot?ww4436#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?ww4436') format('truetype'),
    url('fonts/icomoon.woff?ww4436') format('woff'),
    url('fonts/icomoon.svg?ww4436#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-expand-horizontal-s-line:before {
  content: "\f438";
}
.icon-ic_array:before {
  content: "\f437";
}
.icon-ic_rank_chart:before {
  content: "\f436";
  color: #505866;
}
.icon-endash-line:before {
  content: "\f435";
}
.icon-ic_empty_subscription .path1:before {
  content: "\f433";
  color: rgb(233, 237, 241);
}
.icon-ic_empty_subscription .path2:before {
  content: "\f434";
  margin-left: -1.1337890625em;
  color: rgb(177, 184, 192);
}
.icon-loop-left-line:before {
  content: "\f432";
}
.icon-ic_table_metrics .path1:before {
  content: "\f412";
  color: rgb(255, 255, 255);
}
.icon-ic_table_metrics .path2:before {
  content: "\f413";
  margin-left: -1.5em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_metrics .path3:before {
  content: "\f414";
  margin-left: -1.5em;
  color: rgb(255, 255, 255);
}
.icon-ic_table_metrics .path4:before {
  content: "\f415";
  margin-left: -1.5em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_metrics .path5:before {
  content: "\f416";
  margin-left: -1.5em;
  color: rgb(255, 255, 255);
}
.icon-ic_table_metrics .path6:before {
  content: "\f417";
  margin-left: -1.5em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_metrics .path7:before {
  content: "\f418";
  margin-left: -1.5em;
  color: rgb(255, 255, 255);
}
.icon-ic_table_metrics .path8:before {
  content: "\f419";
  margin-left: -1.5em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_metrics .path9:before {
  content: "\f41a";
  margin-left: -1.5em;
  color: rgb(177, 184, 192);
}
.icon-ic_table_metrics .path10:before {
  content: "\f41b";
  margin-left: -1.5em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_metrics .path11:before {
  content: "\f41c";
  margin-left: -1.5em;
  color: rgb(177, 184, 192);
}
.icon-ic_table_metrics .path12:before {
  content: "\f41d";
  margin-left: -1.5em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_metrics .path13:before {
  content: "\f41e";
  margin-left: -1.5em;
  color: rgb(177, 184, 192);
}
.icon-ic_table_metrics .path14:before {
  content: "\f41f";
  margin-left: -1.5em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_metrics .path15:before {
  content: "\f420";
  margin-left: -1.5em;
  color: rgb(177, 184, 192);
}
.icon-ic_table_metrics .path16:before {
  content: "\f421";
  margin-left: -1.5em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_metrics .path17:before {
  content: "\f422";
  margin-left: -1.5em;
  color: rgb(177, 184, 192);
}
.icon-ic_table_metrics .path18:before {
  content: "\f423";
  margin-left: -1.5em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_metrics .path19:before {
  content: "\f424";
  margin-left: -1.5em;
  color: rgb(177, 184, 192);
}
.icon-ic_table_metrics .path20:before {
  content: "\f425";
  margin-left: -1.5em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_metrics .path21:before {
  content: "\f426";
  margin-left: -1.5em;
  color: rgb(177, 184, 192);
}
.icon-ic_table_metrics .path22:before {
  content: "\f427";
  margin-left: -1.5em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_metrics .path23:before {
  content: "\f428";
  margin-left: -1.5em;
  color: rgb(177, 184, 192);
}
.icon-ic_table_metrics .path24:before {
  content: "\f429";
  margin-left: -1.5em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_metrics .path25:before {
  content: "\f42a";
  margin-left: -1.5em;
  color: rgb(177, 184, 192);
}
.icon-ic_table_metrics .path26:before {
  content: "\f42b";
  margin-left: -1.5em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_metrics .path27:before {
  content: "\f42c";
  margin-left: -1.5em;
  color: rgb(177, 184, 192);
}
.icon-ic_table_metrics .path28:before {
  content: "\f42d";
  margin-left: -1.5em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_metrics .path29:before {
  content: "\f42e";
  margin-left: -1.5em;
  color: rgb(177, 184, 192);
}
.icon-ic_table_metrics .path30:before {
  content: "\f42f";
  margin-left: -1.5em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_metrics .path31:before {
  content: "\f430";
  margin-left: -1.5em;
  color: rgb(177, 184, 192);
}
.icon-ic_table_metrics .path32:before {
  content: "\f431";
  margin-left: -1.5em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_contents .path1:before {
  content: "\f473";
  color: rgb(177, 184, 192);
}
.icon-ic_table_contents .path2:before {
  content: "\f474";
  margin-left: -1.4765625em;
  color: rgb(177, 184, 192);
}
.icon-ic_table_contents .path3:before {
  content: "\f475";
  margin-left: -1.4765625em;
  color: rgb(177, 184, 192);
}
.icon-ic_table_contents .path4:before {
  content: "\f476";
  margin-left: -1.4765625em;
  color: rgb(177, 184, 192);
}
.icon-ic_table_contents .path5:before {
  content: "\f477";
  margin-left: -1.4765625em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_grouping .path1:before {
  content: "\f453";
  color: rgb(177, 184, 192);
}
.icon-ic_table_grouping .path2:before {
  content: "\f454";
  margin-left: -1.4765625em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_grouping .path3:before {
  content: "\f455";
  margin-left: -1.4765625em;
  color: rgb(255, 255, 255);
}
.icon-ic_table_grouping .path4:before {
  content: "\f456";
  margin-left: -1.4765625em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_grouping .path5:before {
  content: "\f457";
  margin-left: -1.4765625em;
  color: rgb(177, 184, 192);
}
.icon-ic_table_grouping .path6:before {
  content: "\f458";
  margin-left: -1.4765625em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_grouping .path7:before {
  content: "\f459";
  margin-left: -1.4765625em;
  color: rgb(255, 255, 255);
}
.icon-ic_table_grouping .path8:before {
  content: "\f45a";
  margin-left: -1.4765625em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_grouping .path9:before {
  content: "\f45b";
  margin-left: -1.4765625em;
  color: rgb(177, 184, 192);
}
.icon-ic_table_grouping .path10:before {
  content: "\f45c";
  margin-left: -1.4765625em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_grouping .path11:before {
  content: "\f45d";
  margin-left: -1.4765625em;
  color: rgb(255, 255, 255);
}
.icon-ic_table_grouping .path12:before {
  content: "\f45e";
  margin-left: -1.4765625em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_grouping .path13:before {
  content: "\f45f";
  margin-left: -1.4765625em;
  color: rgb(177, 184, 192);
}
.icon-ic_table_grouping .path14:before {
  content: "\f460";
  margin-left: -1.4765625em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_grouping .path15:before {
  content: "\f461";
  margin-left: -1.4765625em;
  color: rgb(255, 255, 255);
}
.icon-ic_table_grouping .path16:before {
  content: "\f462";
  margin-left: -1.4765625em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_grouping .path17:before {
  content: "\f463";
  margin-left: -1.4765625em;
  color: rgb(177, 184, 192);
}
.icon-ic_table_grouping .path18:before {
  content: "\f464";
  margin-left: -1.4765625em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_grouping .path19:before {
  content: "\f465";
  margin-left: -1.4765625em;
  color: rgb(255, 255, 255);
}
.icon-ic_table_grouping .path20:before {
  content: "\f466";
  margin-left: -1.4765625em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_grouping .path21:before {
  content: "\f467";
  margin-left: -1.4765625em;
  color: rgb(177, 184, 192);
}
.icon-ic_table_grouping .path22:before {
  content: "\f468";
  margin-left: -1.4765625em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_grouping .path23:before {
  content: "\f469";
  margin-left: -1.4765625em;
  color: rgb(255, 255, 255);
}
.icon-ic_table_grouping .path24:before {
  content: "\f46a";
  margin-left: -1.4765625em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_grouping .path25:before {
  content: "\f46b";
  margin-left: -1.4765625em;
  color: rgb(177, 184, 192);
}
.icon-ic_table_grouping .path26:before {
  content: "\f46c";
  margin-left: -1.4765625em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_grouping .path27:before {
  content: "\f46d";
  margin-left: -1.4765625em;
  color: rgb(255, 255, 255);
}
.icon-ic_table_grouping .path28:before {
  content: "\f46e";
  margin-left: -1.4765625em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_grouping .path29:before {
  content: "\f46f";
  margin-left: -1.4765625em;
  color: rgb(177, 184, 192);
}
.icon-ic_table_grouping .path30:before {
  content: "\f470";
  margin-left: -1.4765625em;
  color: rgb(217, 224, 232);
}
.icon-ic_table_grouping .path31:before {
  content: "\f471";
  margin-left: -1.4765625em;
  color: rgb(255, 255, 255);
}
.icon-ic_table_grouping .path32:before {
  content: "\f472";
  margin-left: -1.4765625em;
  color: rgb(217, 224, 232);
}
.icon-ic_comparison_method:before {
  content: "\f452";
  color: #505866;
}
.icon-collapse-diagonal-line:before {
  content: "\f410";
  color: #505866;
}
.icon-expand-diagonal-line:before {
  content: "\f411";
  color: #505866;
}
.icon-fx-add:before {
  content: "\f40e";
  color: #505866;
}
.icon-fx:before {
  content: "\f40f";
  color: #505866;
}
.icon-conditional_format .path1:before {
  content: "\f40a";
  color: rgb(242, 201, 76);
}
.icon-conditional_format .path2:before {
  content: "\f40b";
  margin-left: -1em;
  color: rgb(39, 174, 96);
}
.icon-conditional_format .path3:before {
  content: "\f40c";
  margin-left: -1em;
  color: rgb(235, 87, 87);
}
.icon-conditional_format .path4:before {
  content: "\f40d";
  margin-left: -1em;
  color: rgb(80, 88, 102);
}
.icon-ic_normalized_bar_chart_24:before {
  content: "\f409";
}
.icon-ic_color_setting .path1:before {
  content: "\f406";
  color: rgb(242, 201, 76);
}
.icon-ic_color_setting .path2:before {
  content: "\f407";
  margin-left: -1em;
  color: rgb(103, 19, 239);
}
.icon-ic_color_setting .path3:before {
  content: "\f408";
  margin-left: -1em;
  color: rgb(235, 87, 87);
}
.icon-ic_progress_bar_24:before {
  content: "\f405";
}
.icon-archive-2-fill:before {
  content: "\f3e9";
}
.icon-archive-2-line:before {
  content: "\f3ea";
}
.icon-archive-stack-fill:before {
  content: "\f3eb";
}
.icon-archive-stack-line:before {
  content: "\f3ec";
}
.icon-calendar-close-fill:before {
  content: "\f3ed";
}
.icon-calendar-close-line:before {
  content: "\f3ee";
}
.icon-calendar-schedule-fill:before {
  content: "\f3ef";
}
.icon-calendar-schedule-line:before {
  content: "\f3f0";
}
.icon-id-card-fill:before {
  content: "\f3f1";
}
.icon-id-card-line:before {
  content: "\f3f2";
}
.icon-inbox-2-fill:before {
  content: "\f3f3";
}
.icon-inbox-2-line:before {
  content: "\f3f4";
}
.icon-megaphone-fill:before {
  content: "\f3f5";
}
.icon-megaphone-line:before {
  content: "\f3f6";
}
.icon-pass-expired-fill:before {
  content: "\f3f7";
}
.icon-pass-expired-line:before {
  content: "\f3f8";
}
.icon-pass-pending-fill:before {
  content: "\f3f9";
}
.icon-pass-pending-line:before {
  content: "\f3fa";
}
.icon-pass-valid-fill:before {
  content: "\f3fb";
}
.icon-pass-valid-line:before {
  content: "\f3fc";
}
.icon-presentation-fill:before {
  content: "\f3fd";
}
.icon-presentation-line:before {
  content: "\f3fe";
}
.icon-seo-fill:before {
  content: "\f3ff";
}
.icon-seo-line:before {
  content: "\f400";
}
.icon-shake-hands-fill:before {
  content: "\f401";
}
.icon-shake-hands-line:before {
  content: "\f402";
}
.icon-verified-badge-fill:before {
  content: "\f403";
}
.icon-verified-badge-line:before {
  content: "\f404";
}
.icon-ic_pivot_create_72 .path1:before {
  content: "\ef98";
  color: rgb(233, 237, 241);
}
.icon-ic_pivot_create_72 .path2:before {
  content: "\ef99";
  margin-left: -1em;
  color: rgb(217, 224, 232);
}
.icon-ic_pivot_create_72 .path3:before {
  content: "\ef9a";
  margin-left: -1em;
  color: rgb(217, 224, 232);
}
.icon-ic_pivot_create_72 .path4:before {
  content: "\ef9b";
  margin-left: -1em;
  color: rgb(217, 224, 232);
}
.icon-ic_pivot_create_72 .path5:before {
  content: "\ef9c";
  margin-left: -1em;
  color: rgb(217, 224, 232);
}
.icon-ic_pivot_create_72 .path6:before {
  content: "\ef9d";
  margin-left: -1em;
  color: rgb(217, 224, 232);
}
.icon-ic_pivot_create_72 .path7:before {
  content: "\ef9e";
  margin-left: -1em;
  color: rgb(217, 224, 232);
}
.icon-ic_pivot_create_72 .path8:before {
  content: "\ef9f";
  margin-left: -1em;
  color: rgb(217, 224, 232);
}
.icon-ic_pivot_create_72 .path9:before {
  content: "\efa0";
  margin-left: -1em;
  color: rgb(217, 224, 232);
}
.icon-ic_pivot_create_72 .path10:before {
  content: "\efa1";
  margin-left: -1em;
  color: rgb(217, 224, 232);
}
.icon-ic_pivot_create_72 .path11:before {
  content: "\efa2";
  margin-left: -1em;
  color: rgb(217, 224, 232);
}
.icon-ic_pivot_create_72 .path12:before {
  content: "\efa3";
  margin-left: -1em;
  color: rgb(217, 224, 232);
}
.icon-ic_pivot_create_72 .path13:before {
  content: "\efa4";
  margin-left: -1em;
  color: rgb(217, 224, 232);
}
.icon-ic_pivot_create_72 .path14:before {
  content: "\efa5";
  margin-left: -1em;
  color: rgb(217, 224, 232);
}
.icon-ic_pivot_create_72 .path15:before {
  content: "\efa6";
  margin-left: -1em;
  color: rgb(217, 224, 232);
}
.icon-ic_pivot_create_72 .path16:before {
  content: "\efa7";
  margin-left: -1em;
  color: rgb(217, 224, 232);
}
.icon-ic_pivot_values_40:before {
  content: "\efa8";
}
.icon-ic_pivot_rows_40:before {
  content: "\efa9";
}
.icon-ic_pivot_columns_40:before {
  content: "\efaa";
}
.icon-ic_chart_28_pivot:before {
  content: "\efab";
}
.icon-ic_google .path1:before {
  content: "\ef94";
  color: rgb(66, 133, 244);
}
.icon-ic_google .path2:before {
  content: "\ef95";
  margin-left: -1em;
  color: rgb(52, 168, 83);
}
.icon-ic_google .path3:before {
  content: "\ef96";
  margin-left: -1em;
  color: rgb(251, 188, 5);
}
.icon-ic_google .path4:before {
  content: "\ef97";
  margin-left: -1em;
  color: rgb(234, 67, 53);
}
.icon-ic_zendesk_chevron:before {
  content: "\ea08";
}
.icon-ic_zendesk_bubble:before {
  content: "\ea07";
}
.icon-ic_something_wrong_40 .path1:before {
  content: "\e9ef";
  color: rgb(242, 244, 246);
}
.icon-ic_something_wrong_40 .path2:before {
  content: "\e9f0";
  margin-left: -1.0126953125em;
  color: rgb(242, 244, 246);
}
.icon-ic_something_wrong_40 .path3:before {
  content: "\e9f1";
  margin-left: -1.0126953125em;
  color: rgb(235, 87, 87);
}
.icon-ic_something_wrong_40 .path4:before {
  content: "\e9f2";
  margin-left: -1.0126953125em;
  color: rgb(235, 87, 87);
}
.icon-ic_question_mark .path1:before {
  content: "\e9ec";
  color: rgb(217, 224, 232);
}
.icon-ic_question_mark .path2:before {
  content: "\e9ed";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ic_question_mark .path3:before {
  content: "\e9ee";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ic_downstream_dm:before {
  content: "\e9ea";
}
.icon-ic_upstream_dm:before {
  content: "\e9eb";
}
.icon-ic_timeline_first:before {
  content: "\e9e7";
}
.icon-ic_timeline_last:before {
  content: "\e9e8";
}
.icon-ic_timeline_middle:before {
  content: "\e9e9";
}
.icon-ic_time_18:before {
  content: "\e9e5";
}
.icon-ic_user_16:before {
  content: "\e9e6";
}
.icon-ic_nothing_to_show_72 .path1:before {
  content: "\e9e1";
  color: rgb(233, 237, 241);
}
.icon-ic_nothing_to_show_72 .path2:before {
  content: "\e9e2";
  margin-left: -1em;
  color: rgb(217, 224, 232);
}
.icon-ic_nothing_to_show_72 .path3:before {
  content: "\e9e3";
  margin-left: -1em;
  color: rgb(233, 237, 241);
}
.icon-ic_nothing_to_show_72 .path4:before {
  content: "\e9e4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ic_remove_description:before {
  content: "\e9e0";
}
.icon-ic_sort_20_rounded_desc .path1:before {
  content: "\e9dc";
  color: rgb(177, 184, 192);
}
.icon-ic_sort_20_rounded_desc .path2:before {
  content: "\e9dd";
  margin-left: -1em;
  color: rgb(80, 88, 102);
}
.icon-ic_sort_20_rounded_asc .path1:before {
  content: "\e9de";
  color: rgb(80, 88, 102);
}
.icon-ic_sort_20_rounded_asc .path2:before {
  content: "\e9df";
  margin-left: -1em;
  color: rgb(177, 184, 192);
}
.icon-ic_check_mark_12:before {
  content: "\e9da";
  color: #27ae60;
}
.icon-ic_collection_type_16:before {
  content: "\e9db";
  color: #505866;
}
.icon-ic_sort_20:before {
  content: "\e9d9";
  color: #b1b8c0;
}
.icon-ic_data_model_list_16:before {
  content: "\e9d8";
  color: #505866;
}
.icon-ic_sidemenu_16:before {
  content: "\e9d7";
}
.icon-ic_menu_connect_16:before {
  content: "\e909";
}
.icon-ic_menu_dashboard_16:before {
  content: "\e9f8";
}
.icon-ic_menu_transform_16:before {
  content: "\e9f9";
}
.icon-ic_ai_18:before {
  content: "\e908";
}
.icon-ic_tab_12:before {
  content: "\e906";
}
.icon-ic_chart_28_error:before {
  content: "\e907";
}
.icon-ic_chart_28_table1:before {
  content: "\ea6f";
}
.icon-ic_chart_28_bar1:before {
  content: "\ea70";
}
.icon-ic_chart_28_line1:before {
  content: "\ea71";
}
.icon-ic_chart_28_pie1:before {
  content: "\ea72";
}
.icon-ic_chart_28_board:before {
  content: "\ea73";
}
.icon-ic_stop_18:before {
  content: "\ea74";
}
.icon-ic_run-again_18:before {
  content: "\ea75";
}
.icon-ic_history_18:before {
  content: "\ea76";
}
.icon-ic_send_16:before {
  content: "\ea77";
}
.icon-ic_magic_16:before {
  content: "\ea78";
}
.icon-ic_archive:before {
  content: "\e9c3";
  color: #505866;
}
.icon-ic_move_folder:before {
  content: "\e9d1";
  color: #505866;
}
.icon-ic_description:before {
  content: "\e9d2";
}
.icon-ic_rename:before {
  content: "\e9cb";
}
.icon-ic_add_report:before {
  content: "\e9cc";
}
.icon-ic_add_note:before {
  content: "\e9ce";
}
.icon-ic_add_column:before {
  content: "\e96d";
}
.icon-ic_add_fav:before {
  content: "\e9c1";
}
.icon-ic_add_row:before {
  content: "\e9c2";
}
.icon-ic_grouping:before {
  content: "\e9c4";
}
.icon-ic_playground:before {
  content: "\e9c5";
}
.icon-ic_reload:before {
  content: "\e9c6";
}
.icon-ic_remove_fav:before {
  content: "\e9c7";
}
.icon-ic_share:before {
  content: "\e9c8";
}
.icon-ic_template:before {
  content: "\e9c9";
  color: #505866;
}
.icon-ic_write_query:before {
  content: "\e9ca";
}
.icon-ic_model_empty:before {
  content: "\e9cd";
}
.icon-ic_data_model:before {
  content: "\e9cf";
}
.icon-ic_duplicate:before {
  content: "\e9d0";
}
.icon-ic_export_16:before {
  content: "\ea6e";
  color: #b1b8c0;
}
.icon-ic_calendar_72 .path1:before {
  content: "\ea5e";
  color: rgb(233, 237, 241);
}
.icon-ic_calendar_72 .path2:before {
  content: "\ea5f";
  margin-left: -0.9091796875em;
  color: rgb(217, 224, 232);
}
.icon-ic_calendar_72 .path3:before {
  content: "\ea60";
  margin-left: -0.9091796875em;
  color: rgb(233, 237, 241);
}
.icon-ic_calendar_72 .path4:before {
  content: "\ea61";
  margin-left: -0.9091796875em;
  color: rgb(233, 237, 241);
}
.icon-ic_calendar_72 .path5:before {
  content: "\ea62";
  margin-left: -0.9091796875em;
  color: rgb(217, 224, 232);
}
.icon-ic_calendar_72 .path6:before {
  content: "\ea63";
  margin-left: -0.9091796875em;
  color: rgb(217, 224, 232);
}
.icon-ic_calendar_72 .path7:before {
  content: "\ea64";
  margin-left: -0.9091796875em;
  color: rgb(217, 224, 232);
}
.icon-ic_calendar_72 .path8:before {
  content: "\ea65";
  margin-left: -0.9091796875em;
  color: rgb(217, 224, 232);
}
.icon-ic_calendar_72 .path9:before {
  content: "\ea66";
  margin-left: -0.9091796875em;
  color: rgb(217, 224, 232);
}
.icon-ic_calendar_72 .path10:before {
  content: "\ea67";
  margin-left: -0.9091796875em;
  color: rgb(217, 224, 232);
}
.icon-ic_calendar_72 .path11:before {
  content: "\ea68";
  margin-left: -0.9091796875em;
  color: rgb(217, 224, 232);
}
.icon-ic_calendar_72 .path12:before {
  content: "\ea69";
  margin-left: -0.9091796875em;
  color: rgb(217, 224, 232);
}
.icon-ic_calendar_72 .path13:before {
  content: "\ea6a";
  margin-left: -0.9091796875em;
  color: rgb(217, 224, 232);
}
.icon-ic_calendar_72 .path14:before {
  content: "\ea6b";
  margin-left: -0.9091796875em;
  color: rgb(217, 224, 232);
}
.icon-ic_calendar_72 .path15:before {
  content: "\ea6c";
  margin-left: -0.9091796875em;
  color: rgb(217, 224, 232);
}
.icon-ic_calendar_72 .path16:before {
  content: "\ea6d";
  margin-left: -0.9091796875em;
  color: rgb(217, 224, 232);
}
.icon-ic_un_pin_18:before {
  content: "\ea5d";
  color: #b1b8c0;
}
.icon-ic_pin_18:before {
  content: "\ea5c";
}
.icon-ic_all_filtered_100 .path1:before {
  content: "\e9a6";
  color: rgb(233, 237, 241);
}
.icon-ic_all_filtered_100 .path2:before {
  content: "\e9a7";
  margin-left: -1em;
  color: rgb(217, 224, 232);
}
.icon-ic_all_filtered_100 .path3:before {
  content: "\e9a8";
  margin-left: -1em;
  color: rgb(217, 224, 232);
}
.icon-ic_all_filtered_100 .path4:before {
  content: "\e9a9";
  margin-left: -1em;
  color: rgb(233, 237, 241);
}
.icon-ic_all_filtered_100 .path5:before {
  content: "\e9aa";
  margin-left: -1em;
  color: rgb(233, 237, 241);
}
.icon-ic_all_filtered_100 .path6:before {
  content: "\e9ab";
  margin-left: -1em;
  color: rgb(217, 224, 232);
}
.icon-ic_chevron_12 .path1:before {
  content: "\e9a2";
  color: rgb(248, 248, 249);
}
.icon-ic_chevron_12 .path2:before {
  content: "\e9a3";
  margin-left: -1em;
  color: rgb(177, 184, 192);
}
.icon-ic_chevron_square_12 .path1:before {
  content: "\e9b7";
  color: rgb(240, 231, 253);
}
.icon-ic_chevron_square_12 .path2:before {
  content: "\e9b8";
  margin-left: -1em;
  color: rgb(103, 19, 239);
}
.icon-ic_triangle_down_16:before {
  content: "\e99d";
}
.icon-ic_triangle_16:before {
  content: "\e9a1";
}
.icon-ic_table_28:before {
  content: "\e99c";
}
.icon-ic_board_28:before {
  content: "\e99b";
}
.icon-ic_status_bullet .path1:before {
  content: "\e999";
  color: rgb(235, 87, 87);
}
.icon-ic_status_bullet .path2:before {
  content: "\e99a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ic_Delete_12:before {
  content: "\e9a0";
}
.icon-ic_setting:before {
  content: "\e998";
}
.icon-ic_advance_filter1:before {
  content: "\e99f";
  color: #505866;
}
.icon-ic_boolean:before {
  content: "\e9b9";
  color: #505866;
}
.icon-ic_filter_24_off .path1:before {
  content: "\e986";
  color: rgb(80, 88, 102);
}
.icon-ic_filter_24_off .path2:before {
  content: "\e987";
  margin-left: -1em;
  color: rgb(80, 88, 102);
}
.icon-ic_filter_24_off .path3:before {
  content: "\e988";
  margin-left: -1em;
  color: rgb(80, 88, 102);
}
.icon-ic_filter_24_off .path4:before {
  content: "\e989";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ic_filter_24_off .path5:before {
  content: "\e98a";
  margin-left: -1em;
  color: rgb(80, 88, 102);
}
.icon-ic_filter_24_off .path6:before {
  content: "\e98b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ic_filter_24_off .path7:before {
  content: "\e98c";
  margin-left: -1em;
  color: rgb(80, 88, 102);
}
.icon-ic_filter_24_off .path8:before {
  content: "\e98d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ic_filter_24_off .path9:before {
  content: "\e98e";
  margin-left: -1em;
  color: rgb(80, 88, 102);
}
.icon-ic_filter_24_on .path1:before {
  content: "\e98f";
  color: rgb(103, 19, 239);
}
.icon-ic_filter_24_on .path2:before {
  content: "\e990";
  margin-left: -1em;
  color: rgb(103, 19, 239);
}
.icon-ic_filter_24_on .path3:before {
  content: "\e991";
  margin-left: -1em;
  color: rgb(103, 19, 239);
}
.icon-ic_filter_24_on .path4:before {
  content: "\e992";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ic_filter_24_on .path5:before {
  content: "\e993";
  margin-left: -1em;
  color: rgb(103, 19, 239);
}
.icon-ic_filter_24_on .path6:before {
  content: "\e994";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ic_filter_24_on .path7:before {
  content: "\e995";
  margin-left: -1em;
  color: rgb(103, 19, 239);
}
.icon-ic_filter_24_on .path8:before {
  content: "\e996";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ic_filter_24_on .path9:before {
  content: "\e997";
  margin-left: -1em;
  color: rgb(103, 19, 239);
}
.icon-ic_cb_indeterminate .path1:before {
  content: "\e96a";
  color: rgb(149, 90, 244);
}
.icon-ic_cb_indeterminate .path2:before {
  content: "\e96b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ic_cb_active .path1:before {
  content: "\e971";
  color: rgb(149, 90, 244);
}
.icon-ic_cb_active .path2:before {
  content: "\e972";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ic_filters_16:before {
  content: "\e969";
}
.icon-ic_upload_56:before {
  content: "\e955";
}
.icon-ic_side_menu_24_favorite_outlined:before {
  content: "\e96c";
}
.icon-ic_sidemenu_open_24:before {
  content: "\e910";
}
.icon-ic_data_update_replace_72:before {
  content: "\e983";
}
.icon-ic_data_update_add_72:before {
  content: "\e984";
}
.icon-ic_sidemenu_close_24:before {
  content: "\e97d";
}
.icon-ic_data_categories_ad_18:before {
  content: "\e97e";
}
.icon-ic_data_categories_all_18:before {
  content: "\e97f";
}
.icon-ic_data_categories_analysis_18:before {
  content: "\e980";
}
.icon-ic_data_categories_file_18:before {
  content: "\e981";
}
.icon-ic_data_categories_sales_18:before {
  content: "\e982";
}
.icon-ic_file_18:before {
  content: "\e97c";
}
.icon-ic_connected_18_disabled:before {
  content: "\e979";
  color: #505866;
}
.icon-ic_connected_18_failed:before {
  content: "\e97a";
  color: #eb5757;
}
.icon-ic_connected_18_enabled:before {
  content: "\e97b";
  color: #27ae60;
}
.icon-ic_image_source .path1:before {
  content: "\e973";
  color: rgb(225, 208, 252);
}
.icon-ic_image_source .path2:before {
  content: "\e974";
  margin-left: -0.7001953125em;
  color: rgb(119, 85, 255);
}
.icon-ic_image_source .path3:before {
  content: "\e975";
  margin-left: -0.7001953125em;
  color: rgb(255, 255, 255);
}
.icon-ic_image_source .path4:before {
  content: "\e976";
  margin-left: -0.7001953125em;
  color: rgb(255, 255, 255);
}
.icon-ic_image_source .path5:before {
  content: "\e977";
  margin-left: -0.7001953125em;
  color: rgb(255, 255, 255);
}
.icon-ic_image_source .path6:before {
  content: "\e978";
  margin-left: -0.7001953125em;
  color: rgb(255, 255, 255);
}
.icon-ic_image_source .path7:before {
  content: "\e985";
  margin-left: -0.7001953125em;
  color: rgb(255, 255, 255);
}
.icon-ic_chart_28_pie:before {
  content: "\e9d3";
}
.icon-ic_bar_chart_24_grouped:before {
  content: "\e900";
}
.icon-ic_bar_chart_24_horizontal:before {
  content: "\e901";
}
.icon-ic_bar_chart_24_stacked:before {
  content: "\e902";
}
.icon-ic_bar_chart_24_vertical:before {
  content: "\e903";
}
.icon-ic_bullet_4:before {
  content: "\e904";
}
.icon-ic_calendar_16:before {
  content: "\e905";
}
.icon-ic_chart_28_bar:before {
  content: "\e9d4";
}
.icon-ic_chart_28_line:before {
  content: "\e9d5";
}
.icon-ic_chart_28_table:before {
  content: "\e9d6";
}
.icon-ic_check_12:before {
  content: "\e90a";
}
.icon-ic_chevron_12_down:before {
  content: "\e90b";
}
.icon-ic_chevron_12_left:before {
  content: "\e90c";
}
.icon-ic_chevron_12_right:before {
  content: "\e90d";
}
.icon-ic_chevron_12_up:before {
  content: "\e90e";
}
.icon-ic_chevron_18_down:before {
  content: "\e90f";
}
.icon-ic_chevron_18_right:before {
  content: "\e911";
}
.icon-ic_chevron_18_up:before {
  content: "\e912";
}
.icon-ic_chevron_narrow_12_down:before {
  content: "\e913";
}
.icon-ic_chevron_narrow_12_left:before {
  content: "\e914";
}
.icon-ic_chevron_narrow_12_right:before {
  content: "\e915";
}
.icon-ic_chevron_narrow_12_up:before {
  content: "\e916";
}
.icon-ic_cigro_32:before {
  content: "\e917";
}
.icon-ic_close_10:before {
  content: "\e918";
}
.icon-ic_close_12:before {
  content: "\e919";
}
.icon-ic_create_new_24:before {
  content: "\e91a";
}
.icon-ic_delete_16 .path1:before {
  content: "\e91b";
  color: rgb(242, 244, 246);
}
.icon-ic_delete_16 .path2:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(80, 88, 102);
}
.icon-ic_delete_16 .path3:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(80, 88, 102);
}
.icon-ic_drag_handle_16:before {
  content: "\e91e";
}
.icon-ic_elipsis_12_horizontal:before {
  content: "\e91f";
}
.icon-ic_elipsis_12_vertical:before {
  content: "\e920";
}
.icon-ic_ellipsis_16:before {
  content: "\e921";
}
.icon-ic_ellipsis_24:before {
  content: "\e922";
}
.icon-ic_filters_24_calendar:before {
  content: "\e923";
}
.icon-ic_filters_24_numbers:before {
  content: "\e924";
}
.icon-ic_filters_24_option .path1:before {
  content: "\e925";
  color: rgb(0, 0, 0);
}
.icon-ic_filters_24_option .path2:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ic_filters_24_option .path3:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ic_filters_24_option .path4:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ic_filters_24_option .path5:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ic_filters_24_option .path6:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ic_filters_24_option .path7:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ic_filters_24_option .path8:before {
  content: "\e92c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ic_filters_24_option .path9:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-ic_filters_24_paper:before {
  content: "\e92e";
}
.icon-ic_filters_24_typography:before {
  content: "\e92f";
}
.icon-ic_image_24:before {
  content: "\e930";
}
.icon-ic_plus_8:before {
  content: "\e931";
}
.icon-ic_plus_12:before {
  content: "\e932";
}
.icon-ic_plus_18:before {
  content: "\e933";
}
.icon-ic_refresh_18:before {
  content: "\e934";
}
.icon-ic_refresh_24:before {
  content: "\e935";
}
.icon-ic_rounded_triangle_10_down:before {
  content: "\e936";
}
.icon-ic_rounded_triangle_10_left:before {
  content: "\e937";
}
.icon-ic_rounded_triangle_10_right:before {
  content: "\e938";
}
.icon-ic_rounded_triangle_10_up:before {
  content: "\e939";
}
.icon-ic_search_18:before {
  content: "\e93a";
}
.icon-ic_search_24:before {
  content: "\e93b";
}
.icon-ic_setting_box_24_eye:before {
  content: "\e93c";
}
.icon-ic_setting_box_24_filter:before {
  content: "\e93d";
}
.icon-ic_setting_box_24_folder:before {
  content: "\e93e";
}
.icon-ic_setting_box_24_groups:before {
  content: "\e93f";
}
.icon-ic_setting_box_24_inbox:before {
  content: "\e940";
}
.icon-ic_setting_box_24_list:before {
  content: "\e941";
}
.icon-ic_setting_box_24_table:before {
  content: "\e942";
}
.icon-ic_settings_24:before {
  content: "\e943";
}
.icon-ic_side_menu_24_dashboard:before {
  content: "\e944";
}
.icon-ic_side_menu_24_favorite:before {
  content: "\e945";
}
.icon-ic_sync_18:before {
  content: "\e946";
}
.icon-ic_team_profile_80 .path1:before {
  content: "\e947";
  color: rgb(209, 214, 218);
}
.icon-ic_team_profile_80 .path2:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(217, 224, 232);
}
.icon-ic_team_profile_80 .path3:before {
  content: "\e949";
  margin-left: -1em;
  color: rgb(248, 248, 249);
}
.icon-ic_team_profile_80 .path4:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(248, 248, 249);
}
.icon-ic_toast_20_completed .path1:before {
  content: "\e94b";
  color: rgb(39, 174, 96);
}
.icon-ic_toast_20_completed .path2:before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ic_toast_20_warning_dark .path1:before {
  content: "\e94d";
  color: rgb(80, 88, 102);
}
.icon-ic_toast_20_warning_dark .path2:before {
  content: "\e94e";
  margin-left: -1em;
  color: rgb(248, 248, 249);
}
.icon-ic_toast_20_warning_dark .path3:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(248, 248, 249);
}
.icon-ic_variable_18:before {
  content: "\e950";
}
.icon-plus1:before {
  content: "\e951";
}
.icon-chevron-up:before {
  content: "\e952";
}
.icon-chevron-left:before {
  content: "\e953";
}
.icon-chevron-right:before {
  content: "\e954";
}
.icon-numbers:before {
  content: "\e956";
}
.icon-typography:before {
  content: "\e957";
}
.icon-calendar:before {
  content: "\e958";
}
.icon-line-chart:before {
  content: "\e959";
}
.icon-stacked-chart:before {
  content: "\e95a";
}
.icon-bar-chart:before {
  content: "\e95b";
}
.icon-group:before {
  content: "\e95c";
}
.icon-list-ordered1:before {
  content: "\e95d";
}
.icon-filter:before {
  content: "\e95e";
}
.icon-file-directory:before {
  content: "\e95f";
}
.icon-toast-warning .path1:before {
  content: "\e960";
  color: rgb(235, 87, 87);
}
.icon-toast-warning .path2:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(249, 249, 252);
}
.icon-toast-warning .path3:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(249, 249, 252);
}
.icon-toast-success .path1:before {
  content: "\e963";
  color: rgb(39, 174, 96);
}
.icon-toast-success .path2:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-chevron-down-filled:before {
  content: "\e965";
}
.icon-close:before {
  content: "\e966";
}
.icon-visible:before {
  content: "\e967";
}
.icon-hidden:before {
  content: "\e968";
}
.icon-dashboard:before {
  content: "\e96e";
}
.icon-search:before {
  content: "\e96f";
}
.icon-chevron-down:before {
  content: "\e970";
}
.icon-ic_productivity_categories_18:before {
  content: "\e9ac";
}
.icon-ic_security_categories_18:before {
  content: "\e9ad";
}
.icon-ic_human_resources_categories_18:before {
  content: "\e9ba";
}
.icon-ic_marketing_categories_18:before {
  content: "\e9ae";
}
.icon-ic_finance_categories_18:before {
  content: "\e9bb";
}
.icon-ic_file_categories_18:before {
  content: "\e9a4";
}
.icon-ic_support_categories_18:before {
  content: "\e9af";
}
.icon-ic_engineering_categories_18:before {
  content: "\e9bc";
}
.icon-ic_api_sales_categories_18:before {
  content: "\e9bd";
}
.icon-ic_database_categories_18:before {
  content: "\e9be";
}
.icon-ic_all_categories_18:before {
  content: "\e9bf";
}
.icon-ic_function_categories_18:before {
  content: "\e9a5";
}
.icon-ic_events_categories_18:before {
  content: "\e9c0";
}
.icon-ic_table_group:before {
  content: "\e9b3";
  color: #505866;
}
.icon-ic_timer_clock:before {
  content: "\e9b1";
}
.icon-ic_ouline_clock:before {
  content: "\e9b2";
}
.icon-ic_notification_off_24:before {
  content: "\e9b0";
  color: #505866;
}
.icon-ic_notification_on_24:before {
  content: "\e9b4";
  color: #505866;
}
.icon-ic_circle_checked:before {
  content: "\e99e";
  color: #955af4;
}
.icon-ic_circle_checkbox .path1:before {
  content: "\e9b5";
  color: rgb(255, 255, 255);
}
.icon-ic_circle_checkbox .path2:before {
  content: "\e9b6";
  margin-left: -1em;
  color: rgb(177, 184, 192);
}
.icon-ic_bullet_41:before {
  content: "\e9f4";
}
.icon-ic_team_profile_801 .path1:before {
  content: "\e9f3";
  color: rgb(242, 244, 246);
}
.icon-ic_team_profile_801 .path2:before {
  content: "\e9f5";
  margin-left: -1em;
  color: rgb(248, 248, 249);
}
.icon-ic_team_profile_801 .path3:before {
  content: "\e9f6";
  margin-left: -1em;
  color: rgb(217, 224, 232);
}
.icon-ic_team_profile_801 .path4:before {
  content: "\e9f7";
  margin-left: -1em;
  color: rgb(217, 224, 232);
}
.icon-ic_team_profile_801 .path5:before {
  content: "\e9fa";
  margin-left: -1em;
  color: rgb(217, 224, 232);
}
.icon-ic_team_profile_801 .path6:before {
  content: "\e9fb";
  margin-left: -1em;
  color: rgb(217, 224, 232);
}
.icon-ic_next:before {
  content: "\e9fc";
}
.icon-ic_previous:before {
  content: "\e9fd";
}
.icon-ic_sign_out_18:before {
  content: "\e9fe";
}
.icon-ic_usage_18:before {
  content: "\e9ff";
}
.icon-ic_billing_18:before {
  content: "\ea00";
}
.icon-ic_invite_18:before {
  content: "\ea01";
}
.icon-ic_account_18:before {
  content: "\ea02";
}
.icon-ic_docs_18:before {
  content: "\ea03";
}
.icon-ic_intro_18:before {
  content: "\ea04";
}
.icon-ic_help_24:before {
  content: "\ea05";
}
.icon-ic_outlink_16:before {
  content: "\ea06";
  color: #505866;
}
.icon-blaze-line:before {
  content: "\ea09";
}
.icon-celsius-line:before {
  content: "\ea0a";
}
.icon-cloud-windy-line:before {
  content: "\ea0b";
}
.icon-cloudy-2-line:before {
  content: "\ea0c";
}
.icon-cloudy-line:before {
  content: "\ea0d";
}
.icon-drizzle-line:before {
  content: "\ea0e";
}
.icon-earthquake-line:before {
  content: "\ea0f";
}
.icon-fahrenheit-line:before {
  content: "\ea10";
}
.icon-fire-line:before {
  content: "\ea11";
}
.icon-flashlight-line:before {
  content: "\ea12";
}
.icon-flood-line:before {
  content: "\ea13";
}
.icon-foggy-line:before {
  content: "\ea14";
}
.icon-hail-line:before {
  content: "\ea15";
}
.icon-haze-2-line:before {
  content: "\ea16";
}
.icon-haze-line:before {
  content: "\ea17";
}
.icon-heavy-showers-line:before {
  content: "\ea18";
}
.icon-meteor-line:before {
  content: "\ea19";
}
.icon-mist-line:before {
  content: "\ea1a";
}
.icon-moon-clear-line:before {
  content: "\ea1b";
}
.icon-moon-cloudy-line:before {
  content: "\ea1c";
}
.icon-moon-foggy-line:before {
  content: "\ea1d";
}
.icon-moon-line:before {
  content: "\ea1e";
}
.icon-rainbow-line:before {
  content: "\ea1f";
}
.icon-rainy-line:before {
  content: "\ea20";
}
.icon-showers-line:before {
  content: "\ea21";
}
.icon-snowy-line:before {
  content: "\ea22";
}
.icon-sun-cloudy-line:before {
  content: "\ea23";
}
.icon-sun-foggy-line:before {
  content: "\ea24";
}
.icon-sun-line:before {
  content: "\ea25";
}
.icon-temp-cold-line:before {
  content: "\ea26";
}
.icon-temp-hot-line:before {
  content: "\ea27";
}
.icon-thunderstorms-line:before {
  content: "\ea28";
}
.icon-tornado-line:before {
  content: "\ea29";
}
.icon-typhoon-line:before {
  content: "\ea2a";
}
.icon-windy-line:before {
  content: "\ea2b";
}
.icon-account-box-line:before {
  content: "\ea2c";
}
.icon-account-circle-line:before {
  content: "\ea2d";
}
.icon-account-pin-box-line:before {
  content: "\ea2e";
}
.icon-account-pin-circle-line:before {
  content: "\ea2f";
}
.icon-admin-line:before {
  content: "\ea30";
}
.icon-aliens-line:before {
  content: "\ea31";
}
.icon-bear-smile-line:before {
  content: "\ea32";
}
.icon-body-scan-line:before {
  content: "\ea33";
}
.icon-contacts-line:before {
  content: "\ea34";
}
.icon-criminal-line:before {
  content: "\ea35";
}
.icon-emotion-2-line:before {
  content: "\ea36";
}
.icon-emotion-happy-line:before {
  content: "\ea37";
}
.icon-emotion-laugh-line:before {
  content: "\ea38";
}
.icon-emotion-line:before {
  content: "\ea39";
}
.icon-emotion-normal-line:before {
  content: "\ea3a";
}
.icon-emotion-sad-line:before {
  content: "\ea3b";
}
.icon-emotion-unhappy-line:before {
  content: "\ea3c";
}
.icon-genderless-line:before {
  content: "\ea3d";
}
.icon-ghost-2-line:before {
  content: "\ea3e";
}
.icon-ghost-line:before {
  content: "\ea3f";
}
.icon-ghost-smile-line:before {
  content: "\ea40";
}
.icon-group-2-line:before {
  content: "\ea41";
}
.icon-group-line:before {
  content: "\ea42";
}
.icon-men-line:before {
  content: "\ea43";
}
.icon-mickey-line:before {
  content: "\ea44";
}
.icon-open-arm-line:before {
  content: "\ea45";
}
.icon-parent-line:before {
  content: "\ea46";
}
.icon-robot-line:before {
  content: "\ea47";
}
.icon-skull-2-line:before {
  content: "\ea48";
}
.icon-skull-line:before {
  content: "\ea49";
}
.icon-spy-line:before {
  content: "\ea4a";
}
.icon-star-smile-line:before {
  content: "\ea4b";
}
.icon-team-line:before {
  content: "\ea4c";
}
.icon-travesti-line:before {
  content: "\ea4d";
}
.icon-user-2-line:before {
  content: "\ea4e";
}
.icon-user-3-line:before {
  content: "\ea4f";
}
.icon-user-4-line:before {
  content: "\ea50";
}
.icon-user-5-line:before {
  content: "\ea51";
}
.icon-user-6-line:before {
  content: "\ea52";
}
.icon-user-add-line:before {
  content: "\ea53";
}
.icon-user-follow-line:before {
  content: "\ea54";
}
.icon-user-heart-line:before {
  content: "\ea55";
}
.icon-user-line:before {
  content: "\ea56";
}
.icon-user-location-line:before {
  content: "\ea57";
}
.icon-user-received-2-line:before {
  content: "\ea58";
}
.icon-user-received-line:before {
  content: "\ea59";
}
.icon-user-search-line:before {
  content: "\ea5a";
}
.icon-user-settings-line:before {
  content: "\ea5b";
}
.icon-user-shared-2-line:before {
  content: "\ea79";
}
.icon-user-shared-line:before {
  content: "\ea7a";
}
.icon-user-smile-line:before {
  content: "\ea7b";
}
.icon-user-star-line:before {
  content: "\ea7c";
}
.icon-user-unfollow-line:before {
  content: "\ea7d";
}
.icon-user-voice-line:before {
  content: "\ea7e";
}
.icon-women-line:before {
  content: "\ea7f";
}
.icon-add-box-line:before {
  content: "\ea80";
}
.icon-add-circle-line:before {
  content: "\ea81";
}
.icon-add-line:before {
  content: "\ea82";
}
.icon-alarm-line:before {
  content: "\ea83";
}
.icon-alarm-warning-line:before {
  content: "\ea84";
}
.icon-alert-line:before {
  content: "\ea85";
}
.icon-apps-2-line:before {
  content: "\ea86";
}
.icon-apps-line:before {
  content: "\ea87";
}
.icon-arrow-down-circle-line:before {
  content: "\ea88";
}
.icon-arrow-down-line:before {
  content: "\ea89";
}
.icon-arrow-down-s-line:before {
  content: "\ea8a";
}
.icon-arrow-drop-down-line:before {
  content: "\ea8b";
}
.icon-arrow-drop-left-line:before {
  content: "\ea8c";
}
.icon-arrow-drop-right-line:before {
  content: "\ea8d";
}
.icon-arrow-drop-up-line:before {
  content: "\ea8e";
}
.icon-arrow-go-back-line:before {
  content: "\ea8f";
}
.icon-arrow-go-forward-line:before {
  content: "\ea90";
}
.icon-arrow-left-circle-line:before {
  content: "\ea91";
}
.icon-arrow-left-down-line:before {
  content: "\ea92";
}
.icon-arrow-left-line:before {
  content: "\ea93";
}
.icon-arrow-left-right-line:before {
  content: "\ea94";
}
.icon-arrow-left-s-line:before {
  content: "\ea95";
}
.icon-arrow-left-up-line:before {
  content: "\ea96";
}
.icon-arrow-right-circle-line:before {
  content: "\ea97";
}
.icon-arrow-right-down-line:before {
  content: "\ea98";
}
.icon-arrow-right-line:before {
  content: "\ea99";
}
.icon-arrow-right-s-line:before {
  content: "\ea9a";
}
.icon-arrow-right-up-line:before {
  content: "\ea9b";
}
.icon-arrow-up-circle-line:before {
  content: "\ea9c";
}
.icon-arrow-up-down-line:before {
  content: "\ea9d";
}
.icon-arrow-up-line:before {
  content: "\ea9e";
}
.icon-arrow-up-s-line:before {
  content: "\ea9f";
}
.icon-check-double-line:before {
  content: "\eaa0";
}
.icon-check-line:before {
  content: "\eaa1";
}
.icon-checkbox-blank-circle-line:before {
  content: "\eaa2";
}
.icon-checkbox-blank-line:before {
  content: "\eaa3";
}
.icon-checkbox-circle-line:before {
  content: "\eaa4";
}
.icon-checkbox-indeterminate-line:before {
  content: "\eaa5";
}
.icon-checkbox-line:before {
  content: "\eaa6";
}
.icon-checkbox-multiple-blank-line:before {
  content: "\eaa7";
}
.icon-checkbox-multiple-line:before {
  content: "\eaa8";
}
.icon-close-circle-line:before {
  content: "\eaa9";
}
.icon-close-line:before {
  content: "\eaaa";
}
.icon-dashboard-line:before {
  content: "\eaab";
}
.icon-delete-back-2-line:before {
  content: "\eaac";
}
.icon-delete-back-line:before {
  content: "\eaad";
}
.icon-delete-bin-2-line:before {
  content: "\eaae";
}
.icon-delete-bin-3-line:before {
  content: "\eaaf";
}
.icon-delete-bin-4-line:before {
  content: "\eab0";
}
.icon-delete-bin-5-line:before {
  content: "\eab1";
}
.icon-delete-bin-6-line:before {
  content: "\eab2";
}
.icon-delete-bin-7-line:before {
  content: "\eab3";
}
.icon-delete-bin-line:before {
  content: "\eab4";
}
.icon-divide-line:before {
  content: "\eab5";
}
.icon-download-2-line:before {
  content: "\eab6";
}
.icon-download-cloud-2-line:before {
  content: "\eab7";
}
.icon-download-cloud-line:before {
  content: "\eab8";
}
.icon-download-line:before {
  content: "\eab9";
}
.icon-error-warning-line:before {
  content: "\eaba";
}
.icon-external-link-line:before {
  content: "\eabb";
}
.icon-eye-2-line:before {
  content: "\eabc";
}
.icon-eye-close-line:before {
  content: "\eabd";
}
.icon-eye-line:before {
  content: "\eabe";
}
.icon-eye-off-line:before {
  content: "\eabf";
}
.icon-filter-2-line:before {
  content: "\eac0";
}
.icon-filter-3-line:before {
  content: "\eac1";
}
.icon-filter-line:before {
  content: "\eac2";
}
.icon-filter-off-line:before {
  content: "\eac3";
}
.icon-find-replace-line:before {
  content: "\eac4";
}
.icon-forbid-2-line:before {
  content: "\eac5";
}
.icon-forbid-line:before {
  content: "\eac6";
}
.icon-function-line:before {
  content: "\eac7";
}
.icon-history-line:before {
  content: "\eac8";
}
.icon-indeterminate-circle-line:before {
  content: "\eac9";
}
.icon-information-line:before {
  content: "\eaca";
}
.icon-list-settings-line:before {
  content: "\eacb";
}
.icon-loader-2-line:before {
  content: "\eacc";
}
.icon-loader-3-line:before {
  content: "\eacd";
}
.icon-loader-4-line:before {
  content: "\eace";
}
.icon-loader-5-line:before {
  content: "\eacf";
}
.icon-loader-line:before {
  content: "\ead0";
}
.icon-lock-2-line:before {
  content: "\ead1";
}
.icon-lock-line:before {
  content: "\ead2";
}
.icon-lock-password-line:before {
  content: "\ead3";
}
.icon-lock-unlock-line:before {
  content: "\ead4";
}
.icon-login-box-line:before {
  content: "\ead5";
}
.icon-login-circle-line:before {
  content: "\ead6";
}
.icon-logout-box-line:before {
  content: "\ead7";
}
.icon-logout-box-r-line:before {
  content: "\ead8";
}
.icon-logout-circle-line:before {
  content: "\ead9";
}
.icon-logout-circle-r-line:before {
  content: "\eada";
}
.icon-menu-2-line:before {
  content: "\eadb";
}
.icon-menu-3-line:before {
  content: "\eadc";
}
.icon-menu-4-line:before {
  content: "\eadd";
}
.icon-menu-5-line:before {
  content: "\eade";
}
.icon-menu-add-line:before {
  content: "\eadf";
}
.icon-menu-fold-line:before {
  content: "\eae0";
}
.icon-menu-line:before {
  content: "\eae1";
}
.icon-menu-unfold-line:before {
  content: "\eae2";
}
.icon-more-2-line:before {
  content: "\eae3";
}
.icon-more-line:before {
  content: "\eae4";
}
.icon-notification-badge-line:before {
  content: "\eae5";
}
.icon-question-line:before {
  content: "\eae6";
}
.icon-radio-button-line:before {
  content: "\eae7";
}
.icon-refresh-line:before {
  content: "\eae8";
}
.icon-search-2-line:before {
  content: "\eae9";
}
.icon-search-eye-line:before {
  content: "\eaea";
}
.icon-search-line:before {
  content: "\eaeb";
}
.icon-settings-2-line:before {
  content: "\eaec";
}
.icon-settings-3-line:before {
  content: "\eaed";
}
.icon-settings-4-line:before {
  content: "\eaee";
}
.icon-settings-5-line:before {
  content: "\eaef";
}
.icon-settings-6-line:before {
  content: "\eaf0";
}
.icon-settings-line:before {
  content: "\eaf1";
}
.icon-share-box-line:before {
  content: "\eaf2";
}
.icon-share-circle-line:before {
  content: "\eaf3";
}
.icon-share-forward-2-line:before {
  content: "\eaf4";
}
.icon-share-forward-box-line:before {
  content: "\eaf5";
}
.icon-share-forward-line:before {
  content: "\eaf6";
}
.icon-share-line:before {
  content: "\eaf7";
}
.icon-shield-check-line:before {
  content: "\eaf8";
}
.icon-shield-cross-line:before {
  content: "\eaf9";
}
.icon-shield-flash-line:before {
  content: "\eafa";
}
.icon-shield-keyhole-line:before {
  content: "\eafb";
}
.icon-shield-line:before {
  content: "\eafc";
}
.icon-shield-star-line:before {
  content: "\eafd";
}
.icon-shield-user-line:before {
  content: "\eafe";
}
.icon-side-bar-line:before {
  content: "\eaff";
}
.icon-spam-2-line:before {
  content: "\eb00";
}
.icon-spam-3-line:before {
  content: "\eb01";
}
.icon-spam-line:before {
  content: "\eb02";
}
.icon-star-half-line:before {
  content: "\eb03";
}
.icon-star-half-s-line:before {
  content: "\eb04";
}
.icon-star-line:before {
  content: "\eb05";
}
.icon-star-s-line:before {
  content: "\eb06";
}
.icon-subtract-line:before {
  content: "\eb07";
}
.icon-thumb-down-line:before {
  content: "\eb08";
}
.icon-thumb-up-line:before {
  content: "\eb09";
}
.icon-time-line:before {
  content: "\eb0a";
}
.icon-timer-2-line:before {
  content: "\eb0b";
}
.icon-timer-flash-line:before {
  content: "\eb0c";
}
.icon-timer-line:before {
  content: "\eb0d";
}
.icon-toggle-line:before {
  content: "\eb0e";
}
.icon-upload-2-line:before {
  content: "\eb0f";
}
.icon-upload-cloud-2-line:before {
  content: "\eb10";
}
.icon-upload-cloud-line:before {
  content: "\eb11";
}
.icon-upload-line:before {
  content: "\eb12";
}
.icon-zoom-in-line:before {
  content: "\eb13";
}
.icon-zoom-out-line:before {
  content: "\eb14";
}
.icon-basketball-line:before {
  content: "\eb15";
}
.icon-bell-line:before {
  content: "\eb16";
}
.icon-billiards-line:before {
  content: "\eb17";
}
.icon-boxing-line:before {
  content: "\eb18";
}
.icon-cactus-line:before {
  content: "\eb19";
}
.icon-cake-2-line:before {
  content: "\eb1a";
}
.icon-cake-3-line:before {
  content: "\eb1b";
}
.icon-cake-line:before {
  content: "\eb1c";
}
.icon-character-recognition-line:before {
  content: "\eb1d";
}
.icon-door-closed-line:before {
  content: "\eb1e";
}
.icon-door-line:before {
  content: "\eb1f";
}
.icon-door-lock-box-line:before {
  content: "\eb20";
}
.icon-door-lock-line:before {
  content: "\eb21";
}
.icon-door-open-line:before {
  content: "\eb22";
}
.icon-football-line:before {
  content: "\eb23";
}
.icon-fridge-line:before {
  content: "\eb24";
}
.icon-game-line:before {
  content: "\eb25";
}
.icon-handbag-line:before {
  content: "\eb26";
}
.icon-key-2-line:before {
  content: "\eb27";
}
.icon-key-line:before {
  content: "\eb28";
}
.icon-knife-blood-line:before {
  content: "\eb29";
}
.icon-knife-line:before {
  content: "\eb2a";
}
.icon-leaf-line:before {
  content: "\eb2b";
}
.icon-lightbulb-flash-line:before {
  content: "\eb2c";
}
.icon-lightbulb-line:before {
  content: "\eb2d";
}
.icon-outlet-2-line:before {
  content: "\eb2e";
}
.icon-outlet-line:before {
  content: "\eb2f";
}
.icon-ping-pong-line:before {
  content: "\eb30";
}
.icon-plant-line:before {
  content: "\eb31";
}
.icon-plug-2-line:before {
  content: "\eb32";
}
.icon-plug-line:before {
  content: "\eb33";
}
.icon-recycle-line:before {
  content: "\eb34";
}
.icon-reserved-line:before {
  content: "\eb35";
}
.icon-scales-2-line:before {
  content: "\eb36";
}
.icon-scales-3-line:before {
  content: "\eb37";
}
.icon-scales-line:before {
  content: "\eb38";
}
.icon-seedling-line:before {
  content: "\eb39";
}
.icon-shirt-line:before {
  content: "\eb3a";
}
.icon-sword-line:before {
  content: "\eb3b";
}
.icon-t-shirt-2-line:before {
  content: "\eb3c";
}
.icon-t-shirt-air-line:before {
  content: "\eb3d";
}
.icon-t-shirt-line:before {
  content: "\eb3e";
}
.icon-umbrella-line:before {
  content: "\eb3f";
}
.icon-voice-recognition-line:before {
  content: "\eb40";
}
.icon-wheelchair-line:before {
  content: "\eb41";
}
.icon-k-line:before {
  content: "\eb42";
}
.icon-album-line:before {
  content: "\eb43";
}
.icon-aspect-ratio-line:before {
  content: "\eb44";
}
.icon-broadcast-line:before {
  content: "\eb45";
}
.icon-camera-2-line:before {
  content: "\eb46";
}
.icon-camera-3-line:before {
  content: "\eb47";
}
.icon-camera-lens-fill:before {
  content: "\eb48";
}
.icon-camera-line:before {
  content: "\eb49";
}
.icon-camera-off-line:before {
  content: "\eb4a";
}
.icon-camera-switch-line:before {
  content: "\eb4b";
}
.icon-clapperboard-line:before {
  content: "\eb4c";
}
.icon-closed-captioning-line:before {
  content: "\eb4d";
}
.icon-disc-line:before {
  content: "\eb4e";
}
.icon-dv-line:before {
  content: "\eb4f";
}
.icon-dvd-line:before {
  content: "\eb50";
}
.icon-eject-line:before {
  content: "\eb51";
}
.icon-equalizer-line:before {
  content: "\eb52";
}
.icon-film-line:before {
  content: "\eb53";
}
.icon-fullscreen-exit-line:before {
  content: "\eb54";
}
.icon-fullscreen-line:before {
  content: "\eb55";
}
.icon-gallery-line:before {
  content: "\eb56";
}
.icon-gallery-upload-line:before {
  content: "\eb57";
}
.icon-hd-line:before {
  content: "\eb58";
}
.icon-headphone-line:before {
  content: "\eb59";
}
.icon-hq-line:before {
  content: "\eb5a";
}
.icon-image-2-line:before {
  content: "\eb5b";
}
.icon-image-add-line:before {
  content: "\eb5c";
}
.icon-image-edit-line:before {
  content: "\eb5d";
}
.icon-image-line:before {
  content: "\eb5e";
}
.icon-landscape-line:before {
  content: "\eb5f";
}
.icon-live-line:before {
  content: "\eb60";
}
.icon-mic-2-line:before {
  content: "\eb61";
}
.icon-mic-line:before {
  content: "\eb62";
}
.icon-mic-off-line:before {
  content: "\eb63";
}
.icon-movie-2-line:before {
  content: "\eb64";
}
.icon-movie-line:before {
  content: "\eb65";
}
.icon-music-2-line:before {
  content: "\eb66";
}
.icon-music-line:before {
  content: "\eb67";
}
.icon-mv-line:before {
  content: "\eb68";
}
.icon-notification-2-line:before {
  content: "\eb69";
}
.icon-notification-3-line:before {
  content: "\eb6a";
}
.icon-notification-4-line:before {
  content: "\eb6b";
}
.icon-notification-line:before {
  content: "\eb6c";
}
.icon-notification-off-line:before {
  content: "\eb6d";
}
.icon-order-play-line:before {
  content: "\eb6e";
}
.icon-pause-circle-line:before {
  content: "\eb6f";
}
.icon-pause-line:before {
  content: "\eb70";
}
.icon-pause-mini-line:before {
  content: "\eb71";
}
.icon-phone-camera-line:before {
  content: "\eb72";
}
.icon-picture-in-picture-2-line:before {
  content: "\eb73";
}
.icon-picture-in-picture-exit-line:before {
  content: "\eb74";
}
.icon-picture-in-picture-line:before {
  content: "\eb75";
}
.icon-play-circle-line:before {
  content: "\eb76";
}
.icon-play-line:before {
  content: "\eb77";
}
.icon-play-list-2-line:before {
  content: "\eb78";
}
.icon-play-list-add-line:before {
  content: "\eb79";
}
.icon-play-list-line:before {
  content: "\eb7a";
}
.icon-play-mini-line:before {
  content: "\eb7b";
}
.icon-polaroid-2-line:before {
  content: "\eb7c";
}
.icon-polaroid-line:before {
  content: "\eb7d";
}
.icon-radio-2-line:before {
  content: "\eb7e";
}
.icon-radio-line:before {
  content: "\eb7f";
}
.icon-record-circle-line:before {
  content: "\eb80";
}
.icon-repeat-2-line:before {
  content: "\eb81";
}
.icon-repeat-line:before {
  content: "\eb82";
}
.icon-repeat-one-line:before {
  content: "\eb83";
}
.icon-rewind-line:before {
  content: "\eb84";
}
.icon-rewind-mini-line:before {
  content: "\eb85";
}
.icon-rhythm-line:before {
  content: "\eb86";
}
.icon-shuffle-line:before {
  content: "\eb87";
}
.icon-skip-back-line:before {
  content: "\eb88";
}
.icon-skip-back-mini-line:before {
  content: "\eb89";
}
.icon-skip-forward-line:before {
  content: "\eb8a";
}
.icon-skip-forward-mini-line:before {
  content: "\eb8b";
}
.icon-sound-module-line:before {
  content: "\eb8c";
}
.icon-speaker-2-line:before {
  content: "\eb8d";
}
.icon-speaker-3-line:before {
  content: "\eb8e";
}
.icon-speaker-line:before {
  content: "\eb8f";
}
.icon-speed-line:before {
  content: "\eb90";
}
.icon-speed-mini-line:before {
  content: "\eb91";
}
.icon-stop-circle-line:before {
  content: "\eb92";
}
.icon-stop-line:before {
  content: "\eb93";
}
.icon-stop-mini-line:before {
  content: "\eb94";
}
.icon-surround-sound-line:before {
  content: "\eb95";
}
.icon-tape-line:before {
  content: "\eb96";
}
.icon-video-add-line:before {
  content: "\eb97";
}
.icon-video-download-line:before {
  content: "\eb98";
}
.icon-video-line:before {
  content: "\eb99";
}
.icon-video-upload-line:before {
  content: "\eb9a";
}
.icon-vidicon-2-line:before {
  content: "\eb9b";
}
.icon-vidicon-line:before {
  content: "\eb9c";
}
.icon-voiceprint-line:before {
  content: "\eb9d";
}
.icon-volume-down-line:before {
  content: "\eb9e";
}
.icon-volume-mute-line:before {
  content: "\eb9f";
}
.icon-volume-off-vibrate-line:before {
  content: "\eba0";
}
.icon-volume-up-line:before {
  content: "\eba1";
}
.icon-volume-vibrate-line:before {
  content: "\eba2";
}
.icon-webcam-line:before {
  content: "\eba3";
}
.icon-anchor-line:before {
  content: "\eba4";
}
.icon-barricade-line:before {
  content: "\eba5";
}
.icon-bike-line:before {
  content: "\eba6";
}
.icon-bus-2-line:before {
  content: "\eba7";
}
.icon-bus-line:before {
  content: "\eba8";
}
.icon-bus-wifi-line:before {
  content: "\eba9";
}
.icon-car-line:before {
  content: "\ebaa";
}
.icon-car-washing-line:before {
  content: "\ebab";
}
.icon-caravan-line:before {
  content: "\ebac";
}
.icon-charging-pile-2-line:before {
  content: "\ebad";
}
.icon-charging-pile-line:before {
  content: "\ebae";
}
.icon-china-railway-line:before {
  content: "\ebaf";
}
.icon-compass-2-line:before {
  content: "\ebb0";
}
.icon-compass-3-line:before {
  content: "\ebb1";
}
.icon-compass-4-line:before {
  content: "\ebb2";
}
.icon-compass-discover-line:before {
  content: "\ebb3";
}
.icon-compass-line:before {
  content: "\ebb4";
}
.icon-cup-line:before {
  content: "\ebb5";
}
.icon-direction-line:before {
  content: "\ebb6";
}
.icon-e-bike-2-line:before {
  content: "\ebb7";
}
.icon-e-bike-line:before {
  content: "\ebb8";
}
.icon-earth-line:before {
  content: "\ebb9";
}
.icon-flight-land-line:before {
  content: "\ebba";
}
.icon-flight-takeoff-line:before {
  content: "\ebbb";
}
.icon-footprint-line:before {
  content: "\ebbc";
}
.icon-gas-station-line:before {
  content: "\ebbd";
}
.icon-globe-line:before {
  content: "\ebbe";
}
.icon-goblet-line:before {
  content: "\ebbf";
}
.icon-guide-line:before {
  content: "\ebc0";
}
.icon-hotel-bed-line:before {
  content: "\ebc1";
}
.icon-lifebuoy-line:before {
  content: "\ebc2";
}
.icon-luggage-cart-line:before {
  content: "\ebc3";
}
.icon-luggage-deposit-line:before {
  content: "\ebc4";
}
.icon-map-2-line:before {
  content: "\ebc5";
}
.icon-map-line:before {
  content: "\ebc6";
}
.icon-map-pin-2-line:before {
  content: "\ebc7";
}
.icon-map-pin-3-line:before {
  content: "\ebc8";
}
.icon-map-pin-4-line:before {
  content: "\ebc9";
}
.icon-map-pin-5-line:before {
  content: "\ebca";
}
.icon-map-pin-add-line:before {
  content: "\ebcb";
}
.icon-map-pin-line:before {
  content: "\ebcc";
}
.icon-map-pin-range-line:before {
  content: "\ebcd";
}
.icon-map-pin-time-line:before {
  content: "\ebce";
}
.icon-map-pin-user-line:before {
  content: "\ebcf";
}
.icon-motorbike-line:before {
  content: "\ebd0";
}
.icon-navigation-line:before {
  content: "\ebd1";
}
.icon-oil-line:before {
  content: "\ebd2";
}
.icon-parking-box-line:before {
  content: "\ebd3";
}
.icon-parking-line:before {
  content: "\ebd4";
}
.icon-passport-line:before {
  content: "\ebd5";
}
.icon-pin-distance-line:before {
  content: "\ebd6";
}
.icon-plane-line:before {
  content: "\ebd7";
}
.icon-police-car-line:before {
  content: "\ebd8";
}
.icon-pushpin-2-line:before {
  content: "\ebd9";
}
.icon-pushpin-line:before {
  content: "\ebda";
}
.icon-restaurant-2-line:before {
  content: "\ebdb";
}
.icon-restaurant-line:before {
  content: "\ebdc";
}
.icon-riding-line:before {
  content: "\ebdd";
}
.icon-road-map-line:before {
  content: "\ebde";
}
.icon-roadster-line:before {
  content: "\ebdf";
}
.icon-rocket-2-line:before {
  content: "\ebe0";
}
.icon-rocket-line:before {
  content: "\ebe1";
}
.icon-route-line:before {
  content: "\ebe2";
}
.icon-run-line:before {
  content: "\ebe3";
}
.icon-sailboat-line:before {
  content: "\ebe4";
}
.icon-ship-2-line:before {
  content: "\ebe5";
}
.icon-ship-line:before {
  content: "\ebe6";
}
.icon-signal-tower-line:before {
  content: "\ebe7";
}
.icon-space-ship-line:before {
  content: "\ebe8";
}
.icon-steering-2-line:before {
  content: "\ebe9";
}
.icon-steering-line:before {
  content: "\ebea";
}
.icon-subway-line:before {
  content: "\ebeb";
}
.icon-subway-wifi-line:before {
  content: "\ebec";
}
.icon-suitcase-2-line:before {
  content: "\ebed";
}
.icon-suitcase-3-line:before {
  content: "\ebee";
}
.icon-suitcase-line:before {
  content: "\ebef";
}
.icon-takeaway-line:before {
  content: "\ebf0";
}
.icon-taxi-line:before {
  content: "\ebf1";
}
.icon-taxi-wifi-line:before {
  content: "\ebf2";
}
.icon-traffic-light-line:before {
  content: "\ebf3";
}
.icon-train-line:before {
  content: "\ebf4";
}
.icon-train-wifi-line:before {
  content: "\ebf5";
}
.icon-treasure-map-line:before {
  content: "\ebf6";
}
.icon-truck-line:before {
  content: "\ebf7";
}
.icon-walk-line:before {
  content: "\ebf8";
}
.icon-alipay-line:before {
  content: "\ebf9";
}
.icon-amazon-line:before {
  content: "\ebfa";
}
.icon-android-line:before {
  content: "\ebfb";
}
.icon-angularjs-line:before {
  content: "\ebfc";
}
.icon-app-store-line:before {
  content: "\ebfd";
}
.icon-apple-line:before {
  content: "\ebfe";
}
.icon-baidu-line:before {
  content: "\ebff";
}
.icon-behance-line:before {
  content: "\ec00";
}
.icon-bilibili-line:before {
  content: "\ec01";
}
.icon-centos-line:before {
  content: "\ec02";
}
.icon-chrome-line:before {
  content: "\ec03";
}
.icon-codepen-line:before {
  content: "\ec04";
}
.icon-coreos-line:before {
  content: "\ec05";
}
.icon-dingding-line:before {
  content: "\ec06";
}
.icon-discord-line:before {
  content: "\ec07";
}
.icon-disqus-line:before {
  content: "\ec08";
}
.icon-douban-line:before {
  content: "\ec09";
}
.icon-dribbble-line:before {
  content: "\ec0a";
}
.icon-drive-line:before {
  content: "\ec0b";
}
.icon-dropbox-line:before {
  content: "\ec0c";
}
.icon-edge-line:before {
  content: "\ec0d";
}
.icon-evernote-line:before {
  content: "\ec0e";
}
.icon-facebook-box-line:before {
  content: "\ec0f";
}
.icon-facebook-circle-line:before {
  content: "\ec10";
}
.icon-facebook-line:before {
  content: "\ec11";
}
.icon-finder-line:before {
  content: "\ec12";
}
.icon-firefox-line:before {
  content: "\ec13";
}
.icon-flutter-line:before {
  content: "\ec14";
}
.icon-gatsby-line:before {
  content: "\ec15";
}
.icon-github-line:before {
  content: "\ec16";
}
.icon-gitlab-line:before {
  content: "\ec17";
}
.icon-google-line:before {
  content: "\ec18";
}
.icon-google-play-line:before {
  content: "\ec19";
}
.icon-honor-of-kings-line:before {
  content: "\ec1a";
}
.icon-ie-line:before {
  content: "\ec1b";
}
.icon-instagram-line:before {
  content: "\ec1c";
}
.icon-invision-line:before {
  content: "\ec1d";
}
.icon-kakao-talk-line:before {
  content: "\ec1e";
}
.icon-line-line:before {
  content: "\ec1f";
}
.icon-linkedin-box-line:before {
  content: "\ec20";
}
.icon-linkedin-line:before {
  content: "\ec21";
}
.icon-mastercard-line:before {
  content: "\ec22";
}
.icon-mastodon-line:before {
  content: "\ec23";
}
.icon-medium-line:before {
  content: "\ec24";
}
.icon-messenger-line:before {
  content: "\ec25";
}
.icon-microsoft-line:before {
  content: "\ec26";
}
.icon-mini-program-line:before {
  content: "\ec27";
}
.icon-netease-cloud-music-line:before {
  content: "\ec28";
}
.icon-netflix-line:before {
  content: "\ec29";
}
.icon-npmjs-line:before {
  content: "\ec2a";
}
.icon-open-source-line:before {
  content: "\ec2b";
}
.icon-opera-line:before {
  content: "\ec2c";
}
.icon-patreon-line:before {
  content: "\ec2d";
}
.icon-paypal-line:before {
  content: "\ec2e";
}
.icon-pinterest-line:before {
  content: "\ec2f";
}
.icon-pixelfed-line:before {
  content: "\ec30";
}
.icon-playstation-line:before {
  content: "\ec31";
}
.icon-product-hunt-line:before {
  content: "\ec32";
}
.icon-qq-line:before {
  content: "\ec33";
}
.icon-reactjs-line:before {
  content: "\ec34";
}
.icon-reddit-line:before {
  content: "\ec35";
}
.icon-remixicon-line:before {
  content: "\ec36";
}
.icon-safari-line:before {
  content: "\ec37";
}
.icon-skype-line:before {
  content: "\ec38";
}
.icon-slack-line:before {
  content: "\ec39";
}
.icon-snapchat-line:before {
  content: "\ec3a";
}
.icon-soundcloud-line:before {
  content: "\ec3b";
}
.icon-spectrum-line:before {
  content: "\ec3c";
}
.icon-spotify-line:before {
  content: "\ec3d";
}
.icon-stack-overflow-line:before {
  content: "\ec3e";
}
.icon-stackshare-line:before {
  content: "\ec3f";
}
.icon-steam-line:before {
  content: "\ec40";
}
.icon-switch-line:before {
  content: "\ec41";
}
.icon-taobao-line:before {
  content: "\ec42";
}
.icon-telegram-line:before {
  content: "\ec43";
}
.icon-trello-line:before {
  content: "\ec44";
}
.icon-tumblr-line:before {
  content: "\ec45";
}
.icon-twitch-line:before {
  content: "\ec46";
}
.icon-twitter-line:before {
  content: "\ec47";
}
.icon-ubuntu-line:before {
  content: "\ec48";
}
.icon-unsplash-line:before {
  content: "\ec49";
}
.icon-vimeo-line:before {
  content: "\ec4a";
}
.icon-visa-line:before {
  content: "\ec4b";
}
.icon-vuejs-line:before {
  content: "\ec4c";
}
.icon-wechat-2-line:before {
  content: "\ec4d";
}
.icon-wechat-line:before {
  content: "\ec4e";
}
.icon-wechat-pay-line:before {
  content: "\ec4f";
}
.icon-weibo-line:before {
  content: "\ec50";
}
.icon-whatsapp-line:before {
  content: "\ec51";
}
.icon-windows-line:before {
  content: "\ec52";
}
.icon-xbox-line:before {
  content: "\ec53";
}
.icon-xing-line:before {
  content: "\ec54";
}
.icon-youtube-line:before {
  content: "\ec55";
}
.icon-zcool-line:before {
  content: "\ec56";
}
.icon-zhihu-line:before {
  content: "\ec57";
}
.icon-capsule-line:before {
  content: "\ec58";
}
.icon-dislike-line:before {
  content: "\ec59";
}
.icon-dossier-line:before {
  content: "\ec5a";
}
.icon-empathize-line:before {
  content: "\ec5b";
}
.icon-first-aid-kit-line:before {
  content: "\ec5c";
}
.icon-flask-line:before {
  content: "\ec5d";
}
.icon-hand-sanitizer-line:before {
  content: "\ec5e";
}
.icon-health-book-line:before {
  content: "\ec5f";
}
.icon-heart-2-line:before {
  content: "\ec60";
}
.icon-heart-3-line:before {
  content: "\ec61";
}
.icon-heart-add-line:before {
  content: "\ec62";
}
.icon-heart-line:before {
  content: "\ec63";
}
.icon-heart-pulse-line:before {
  content: "\ec64";
}
.icon-hearts-line:before {
  content: "\ec65";
}
.icon-infrared-thermometer-line:before {
  content: "\ec66";
}
.icon-lungs-line:before {
  content: "\ec67";
}
.icon-medicine-bottle-line:before {
  content: "\ec68";
}
.icon-mental-health-line:before {
  content: "\ec69";
}
.icon-microscope-line:before {
  content: "\ec6a";
}
.icon-nurse-line:before {
  content: "\ec6b";
}
.icon-psychotherapy-line:before {
  content: "\ec6c";
}
.icon-pulse-line:before {
  content: "\ec6d";
}
.icon-rest-time-line:before {
  content: "\ec6e";
}
.icon-stethoscope-line:before {
  content: "\ec6f";
}
.icon-surgical-mask-line:before {
  content: "\ec70";
}
.icon-syringe-line:before {
  content: "\ec71";
}
.icon-test-tube-line:before {
  content: "\ec72";
}
.icon-thermometer-line:before {
  content: "\ec73";
}
.icon-virus-line:before {
  content: "\ec74";
}
.icon-zzz-line:before {
  content: "\ec75";
}
.icon-hours-line:before {
  content: "\ec76";
}
.icon-auction-line:before {
  content: "\ec77";
}
.icon-bank-card-2-line:before {
  content: "\ec78";
}
.icon-bank-card-line:before {
  content: "\ec79";
}
.icon-bit-coin-line:before {
  content: "\ec7a";
}
.icon-coin-line:before {
  content: "\ec7b";
}
.icon-coins-line:before {
  content: "\ec7c";
}
.icon-copper-coin-line:before {
  content: "\ec7d";
}
.icon-copper-diamond-line:before {
  content: "\ec7e";
}
.icon-coupon-2-line:before {
  content: "\ec7f";
}
.icon-coupon-3-line:before {
  content: "\ec80";
}
.icon-coupon-4-line:before {
  content: "\ec81";
}
.icon-coupon-5-line:before {
  content: "\ec82";
}
.icon-coupon-line:before {
  content: "\ec83";
}
.icon-currency-line:before {
  content: "\ec84";
}
.icon-exchange-box-line:before {
  content: "\ec85";
}
.icon-exchange-cny-line:before {
  content: "\ec86";
}
.icon-exchange-dollar-line:before {
  content: "\ec87";
}
.icon-exchange-funds-fill:before {
  content: "\ec88";
}
.icon-exchange-line:before {
  content: "\ec89";
}
.icon-funds-box-line:before {
  content: "\ec8a";
}
.icon-funds-line:before {
  content: "\ec8b";
}
.icon-gift-2-line:before {
  content: "\ec8c";
}
.icon-gift-line:before {
  content: "\ec8d";
}
.icon-hand-coin-line:before {
  content: "\ec8e";
}
.icon-hand-heart-line:before {
  content: "\ec8f";
}
.icon-increase-decrease-line:before {
  content: "\ec90";
}
.icon-money-cny-box-line:before {
  content: "\ec91";
}
.icon-money-cny-circle-line:before {
  content: "\ec92";
}
.icon-money-dollar-box-line:before {
  content: "\ec93";
}
.icon-money-dollar-circle-line:before {
  content: "\ec94";
}
.icon-money-euro-box-line:before {
  content: "\ec95";
}
.icon-money-euro-circle-line:before {
  content: "\ec96";
}
.icon-money-pound-box-line:before {
  content: "\ec97";
}
.icon-money-pound-circle-line:before {
  content: "\ec98";
}
.icon-percent-line:before {
  content: "\ec99";
}
.icon-price-tag-2-line:before {
  content: "\ec9a";
}
.icon-price-tag-3-line:before {
  content: "\ec9b";
}
.icon-price-tag-line:before {
  content: "\ec9c";
}
.icon-red-packet-line:before {
  content: "\ec9d";
}
.icon-refund-2-line:before {
  content: "\ec9e";
}
.icon-refund-line:before {
  content: "\ec9f";
}
.icon-safe-2-line:before {
  content: "\eca0";
}
.icon-safe-line:before {
  content: "\eca1";
}
.icon-secure-payment-line:before {
  content: "\eca2";
}
.icon-shopping-bag-2-line:before {
  content: "\eca3";
}
.icon-shopping-bag-3-line:before {
  content: "\eca4";
}
.icon-shopping-bag-line:before {
  content: "\eca5";
}
.icon-shopping-basket-2-line:before {
  content: "\eca6";
}
.icon-shopping-basket-line:before {
  content: "\eca7";
}
.icon-shopping-cart-2-line:before {
  content: "\eca8";
}
.icon-shopping-cart-line:before {
  content: "\eca9";
}
.icon-stock-line:before {
  content: "\ecaa";
}
.icon-swap-box-line:before {
  content: "\ecab";
}
.icon-swap-line:before {
  content: "\ecac";
}
.icon-ticket-2-line:before {
  content: "\ecad";
}
.icon-ticket-line:before {
  content: "\ecae";
}
.icon-trophy-line:before {
  content: "\ecaf";
}
.icon-vip-crown-2-line:before {
  content: "\ecb0";
}
.icon-vip-crown-line:before {
  content: "\ecb1";
}
.icon-vip-diamond-line:before {
  content: "\ecb2";
}
.icon-vip-line:before {
  content: "\ecb3";
}
.icon-wallet-2-line:before {
  content: "\ecb4";
}
.icon-wallet-3-line:before {
  content: "\ecb5";
}
.icon-wallet-line:before {
  content: "\ecb6";
}
.icon-water-flash-line:before {
  content: "\ecb7";
}
.icon-a-b:before {
  content: "\ecb8";
}
.icon-align-bottom:before {
  content: "\ecb9";
}
.icon-align-center:before {
  content: "\ecba";
}
.icon-align-justify:before {
  content: "\ecbb";
}
.icon-align-left:before {
  content: "\ecbc";
}
.icon-align-right:before {
  content: "\ecbd";
}
.icon-align-top:before {
  content: "\ecbe";
}
.icon-align-vertically:before {
  content: "\ecbf";
}
.icon-asterisk:before {
  content: "\ecc0";
}
.icon-attachment-2:before {
  content: "\ecc1";
}
.icon-bold:before {
  content: "\ecc2";
}
.icon-bring-forward:before {
  content: "\ecc3";
}
.icon-bring-to-front:before {
  content: "\ecc4";
}
.icon-code-view:before {
  content: "\ecc5";
}
.icon-delete-column:before {
  content: "\ecc6";
}
.icon-delete-row:before {
  content: "\ecc7";
}
.icon-double-quotes-l:before {
  content: "\ecc8";
}
.icon-double-quotes-r:before {
  content: "\ecc9";
}
.icon-emphasis-cn:before {
  content: "\ecca";
}
.icon-emphasis:before {
  content: "\eccb";
}
.icon-english-input:before {
  content: "\eccc";
}
.icon-flow-chart:before {
  content: "\eccd";
}
.icon-font-color:before {
  content: "\ecce";
}
.icon-font-size-2:before {
  content: "\eccf";
}
.icon-font-size:before {
  content: "\ecd0";
}
.icon-format-clear:before {
  content: "\ecd1";
}
.icon-functions:before {
  content: "\ecd2";
}
.icon-h-1:before {
  content: "\ecd3";
}
.icon-h-2:before {
  content: "\ecd4";
}
.icon-h-3:before {
  content: "\ecd5";
}
.icon-h-4:before {
  content: "\ecd6";
}
.icon-h-5:before {
  content: "\ecd7";
}
.icon-h-6:before {
  content: "\ecd8";
}
.icon-hashtag:before {
  content: "\ecd9";
}
.icon-heading:before {
  content: "\ecda";
}
.icon-indent-decrease:before {
  content: "\ecdb";
}
.icon-indent-increase:before {
  content: "\ecdc";
}
.icon-input-cursor-move:before {
  content: "\ecdd";
}
.icon-insert-column-left:before {
  content: "\ecde";
}
.icon-insert-column-right:before {
  content: "\ecdf";
}
.icon-insert-row-bottom:before {
  content: "\ece0";
}
.icon-insert-row-top:before {
  content: "\ece1";
}
.icon-italic:before {
  content: "\ece2";
}
.icon-line-height:before {
  content: "\ece3";
}
.icon-link-m:before {
  content: "\ece4";
}
.icon-link-unlink-m:before {
  content: "\ece5";
}
.icon-link-unlink:before {
  content: "\ece6";
}
.icon-link:before {
  content: "\ece7";
}
.icon-list-check-2:before {
  content: "\ece8";
}
.icon-list-check:before {
  content: "\ece9";
}
.icon-list-ordered:before {
  content: "\ecea";
}
.icon-list-unordered:before {
  content: "\eceb";
}
.icon-merge-cells-horizontal:before {
  content: "\ecec";
}
.icon-merge-cells-vertical:before {
  content: "\eced";
}
.icon-mind-map:before {
  content: "\ecee";
}
.icon-node-tree:before {
  content: "\ecef";
}
.icon-number-0:before {
  content: "\ecf0";
}
.icon-number-1:before {
  content: "\ecf1";
}
.icon-number-2:before {
  content: "\ecf2";
}
.icon-number-3:before {
  content: "\ecf3";
}
.icon-number-4:before {
  content: "\ecf4";
}
.icon-number-5:before {
  content: "\ecf5";
}
.icon-number-6:before {
  content: "\ecf6";
}
.icon-number-7:before {
  content: "\ecf7";
}
.icon-number-8:before {
  content: "\ecf8";
}
.icon-number-9:before {
  content: "\ecf9";
}
.icon-omega:before {
  content: "\ecfa";
}
.icon-organization-chart:before {
  content: "\ecfb";
}
.icon-page-separator:before {
  content: "\ecfc";
}
.icon-paragraph:before {
  content: "\ecfd";
}
.icon-pinyin-input:before {
  content: "\ecfe";
}
.icon-question-mark:before {
  content: "\ecff";
}
.icon-rounded-corner:before {
  content: "\ed00";
}
.icon-send-backward:before {
  content: "\ed01";
}
.icon-send-to-back:before {
  content: "\ed02";
}
.icon-separator:before {
  content: "\ed03";
}
.icon-single-quotes-l:before {
  content: "\ed04";
}
.icon-single-quotes-r:before {
  content: "\ed05";
}
.icon-sort-asc:before {
  content: "\ed06";
}
.icon-sort-desc:before {
  content: "\ed07";
}
.icon-space:before {
  content: "\ed08";
}
.icon-split-cells-horizontal:before {
  content: "\ed09";
}
.icon-split-cells-vertical:before {
  content: "\ed0a";
}
.icon-strikethrough-2:before {
  content: "\ed0b";
}
.icon-strikethrough:before {
  content: "\ed0c";
}
.icon-subscript-2:before {
  content: "\ed0d";
}
.icon-subscript:before {
  content: "\ed0e";
}
.icon-superscript-2:before {
  content: "\ed0f";
}
.icon-superscript:before {
  content: "\ed10";
}
.icon-table-2:before {
  content: "\ed11";
}
.icon-text-direction-l:before {
  content: "\ed12";
}
.icon-text-direction-r:before {
  content: "\ed13";
}
.icon-text-spacing:before {
  content: "\ed14";
}
.icon-text-wrap:before {
  content: "\ed15";
}
.icon-text:before {
  content: "\ed16";
}
.icon-translate-2:before {
  content: "\ed17";
}
.icon-translate:before {
  content: "\ed18";
}
.icon-underline:before {
  content: "\ed19";
}
.icon-wubi-input:before {
  content: "\ed1a";
}
.icon-article-line:before {
  content: "\ed1b";
}
.icon-bill-line:before {
  content: "\ed1c";
}
.icon-book-2-line:before {
  content: "\ed1d";
}
.icon-book-3-line:before {
  content: "\ed1e";
}
.icon-book-line:before {
  content: "\ed1f";
}
.icon-book-mark-line:before {
  content: "\ed20";
}
.icon-book-open-line:before {
  content: "\ed21";
}
.icon-book-read-line:before {
  content: "\ed22";
}
.icon-booklet-line:before {
  content: "\ed23";
}
.icon-clipboard-line:before {
  content: "\ed24";
}
.icon-contacts-book-2-line:before {
  content: "\ed25";
}
.icon-contacts-book-line:before {
  content: "\ed26";
}
.icon-contacts-book-upload-line:before {
  content: "\ed27";
}
.icon-draft-line:before {
  content: "\ed28";
}
.icon-file-2-line:before {
  content: "\ed29";
}
.icon-file-3-line:before {
  content: "\ed2a";
}
.icon-file-4-line:before {
  content: "\ed2b";
}
.icon-file-add-line:before {
  content: "\ed2c";
}
.icon-file-chart-2-line:before {
  content: "\ed2d";
}
.icon-file-chart-line:before {
  content: "\ed2e";
}
.icon-file-cloud-line:before {
  content: "\ed2f";
}
.icon-file-code-line:before {
  content: "\ed30";
}
.icon-file-copy-2-line:before {
  content: "\ed31";
}
.icon-file-copy-line:before {
  content: "\ed32";
}
.icon-file-damage-line:before {
  content: "\ed33";
}
.icon-file-download-line:before {
  content: "\ed34";
}
.icon-file-edit-line:before {
  content: "\ed35";
}
.icon-file-excel-2-line:before {
  content: "\ed36";
}
.icon-file-excel-line:before {
  content: "\ed37";
}
.icon-file-forbid-line:before {
  content: "\ed38";
}
.icon-file-gif-line:before {
  content: "\ed39";
}
.icon-file-history-line:before {
  content: "\ed3a";
}
.icon-file-hwp-line:before {
  content: "\ed3b";
}
.icon-file-info-line:before {
  content: "\ed3c";
}
.icon-file-line:before {
  content: "\ed3d";
}
.icon-file-list-2-line:before {
  content: "\ed3e";
}
.icon-file-list-3-line:before {
  content: "\ed3f";
}
.icon-file-list-line:before {
  content: "\ed40";
}
.icon-file-lock-line:before {
  content: "\ed41";
}
.icon-file-mark-line:before {
  content: "\ed42";
}
.icon-file-music-line:before {
  content: "\ed43";
}
.icon-file-paper-2-line:before {
  content: "\ed44";
}
.icon-file-paper-line:before {
  content: "\ed45";
}
.icon-file-pdf-line:before {
  content: "\ed46";
}
.icon-file-ppt-2-line:before {
  content: "\ed47";
}
.icon-file-ppt-line:before {
  content: "\ed48";
}
.icon-file-reduce-line:before {
  content: "\ed49";
}
.icon-file-search-line:before {
  content: "\ed4a";
}
.icon-file-settings-line:before {
  content: "\ed4b";
}
.icon-file-shield-2-line:before {
  content: "\ed4c";
}
.icon-file-shield-line:before {
  content: "\ed4d";
}
.icon-file-shred-line:before {
  content: "\ed4e";
}
.icon-file-text-line:before {
  content: "\ed4f";
}
.icon-file-transfer-line:before {
  content: "\ed50";
}
.icon-file-unknow-line:before {
  content: "\ed51";
}
.icon-file-upload-line:before {
  content: "\ed52";
}
.icon-file-user-line:before {
  content: "\ed53";
}
.icon-file-warning-line:before {
  content: "\ed54";
}
.icon-file-word-2-line:before {
  content: "\ed55";
}
.icon-file-word-line:before {
  content: "\ed56";
}
.icon-file-zip-line:before {
  content: "\ed57";
}
.icon-folder-2-line:before {
  content: "\ed58";
}
.icon-folder-3-line:before {
  content: "\ed59";
}
.icon-folder-4-line:before {
  content: "\ed5a";
}
.icon-folder-5-line:before {
  content: "\ed5b";
}
.icon-folder-add-line:before {
  content: "\ed5c";
}
.icon-folder-chart-2-line:before {
  content: "\ed5d";
}
.icon-folder-chart-line:before {
  content: "\ed5e";
}
.icon-folder-download-line:before {
  content: "\ed5f";
}
.icon-folder-forbid-line:before {
  content: "\ed60";
}
.icon-folder-history-line:before {
  content: "\ed61";
}
.icon-folder-info-line:before {
  content: "\ed62";
}
.icon-folder-keyhole-line:before {
  content: "\ed63";
}
.icon-folder-line:before {
  content: "\ed64";
}
.icon-folder-lock-line:before {
  content: "\ed65";
}
.icon-folder-music-line:before {
  content: "\ed66";
}
.icon-folder-open-line:before {
  content: "\ed67";
}
.icon-folder-received-line:before {
  content: "\ed68";
}
.icon-folder-reduce-line:before {
  content: "\ed69";
}
.icon-folder-settings-line:before {
  content: "\ed6a";
}
.icon-folder-shared-line:before {
  content: "\ed6b";
}
.icon-folder-shield-2-line:before {
  content: "\ed6c";
}
.icon-folder-shield-line:before {
  content: "\ed6d";
}
.icon-folder-transfer-line:before {
  content: "\ed6e";
}
.icon-folder-unknow-line:before {
  content: "\ed6f";
}
.icon-folder-upload-line:before {
  content: "\ed70";
}
.icon-folder-user-line:before {
  content: "\ed71";
}
.icon-folder-warning-line:before {
  content: "\ed72";
}
.icon-folder-zip-line:before {
  content: "\ed73";
}
.icon-folders-line:before {
  content: "\ed74";
}
.icon-keynote-line:before {
  content: "\ed75";
}
.icon-markdown-line:before {
  content: "\ed76";
}
.icon-newspaper-line:before {
  content: "\ed77";
}
.icon-numbers-line:before {
  content: "\ed78";
}
.icon-pages-line:before {
  content: "\ed79";
}
.icon-sticky-note-2-line:before {
  content: "\ed7a";
}
.icon-sticky-note-line:before {
  content: "\ed7b";
}
.icon-survey-line:before {
  content: "\ed7c";
}
.icon-task-line:before {
  content: "\ed7d";
}
.icon-todo-line:before {
  content: "\ed7e";
}
.icon-airplay-line:before {
  content: "\ed7f";
}
.icon-barcode-box-line:before {
  content: "\ed80";
}
.icon-barcode-line:before {
  content: "\ed81";
}
.icon-base-station-line:before {
  content: "\ed82";
}
.icon-battery-2-charge-line:before {
  content: "\ed83";
}
.icon-battery-2-line:before {
  content: "\ed84";
}
.icon-battery-charge-line:before {
  content: "\ed85";
}
.icon-battery-line:before {
  content: "\ed86";
}
.icon-battery-low-line:before {
  content: "\ed87";
}
.icon-battery-saver-line:before {
  content: "\ed88";
}
.icon-battery-share-line:before {
  content: "\ed89";
}
.icon-bluetooth-connect-line:before {
  content: "\ed8a";
}
.icon-bluetooth-line:before {
  content: "\ed8b";
}
.icon-cast-line:before {
  content: "\ed8c";
}
.icon-cellphone-line:before {
  content: "\ed8d";
}
.icon-computer-line:before {
  content: "\ed8e";
}
.icon-cpu-line:before {
  content: "\ed8f";
}
.icon-dashboard-2-line:before {
  content: "\ed90";
}
.icon-dashboard-3-line:before {
  content: "\ed91";
}
.icon-database-2-line:before {
  content: "\ed92";
}
.icon-database-line:before {
  content: "\ed93";
}
.icon-device-line:before {
  content: "\ed94";
}
.icon-device-recover-line:before {
  content: "\ed95";
}
.icon-dual-sim-1-line:before {
  content: "\ed96";
}
.icon-dual-sim-2-line:before {
  content: "\ed97";
}
.icon-fingerprint-2-line:before {
  content: "\ed98";
}
.icon-fingerprint-line:before {
  content: "\ed99";
}
.icon-gamepad-line:before {
  content: "\ed9a";
}
.icon-gps-line:before {
  content: "\ed9b";
}
.icon-gradienter-line:before {
  content: "\ed9c";
}
.icon-hard-drive-2-line:before {
  content: "\ed9d";
}
.icon-hard-drive-line:before {
  content: "\ed9e";
}
.icon-hotspot-line:before {
  content: "\ed9f";
}
.icon-install-line:before {
  content: "\eda0";
}
.icon-keyboard-box-line:before {
  content: "\eda1";
}
.icon-keyboard-line:before {
  content: "\eda2";
}
.icon-mac-line:before {
  content: "\eda3";
}
.icon-macbook-line:before {
  content: "\eda4";
}
.icon-mouse-line:before {
  content: "\eda5";
}
.icon-phone-find-line:before {
  content: "\eda6";
}
.icon-phone-line:before {
  content: "\eda7";
}
.icon-phone-lock-line:before {
  content: "\eda8";
}
.icon-qr-code-line:before {
  content: "\eda9";
}
.icon-qr-scan-2-line:before {
  content: "\edaa";
}
.icon-qr-scan-line:before {
  content: "\edab";
}
.icon-radar-line:before {
  content: "\edac";
}
.icon-remote-control-2-line:before {
  content: "\edad";
}
.icon-remote-control-line:before {
  content: "\edae";
}
.icon-restart-line:before {
  content: "\edaf";
}
.icon-rotate-lock-line:before {
  content: "\edb0";
}
.icon-router-line:before {
  content: "\edb1";
}
.icon-rss-line:before {
  content: "\edb2";
}
.icon-save-2-line:before {
  content: "\edb3";
}
.icon-save-3-line:before {
  content: "\edb4";
}
.icon-save-line:before {
  content: "\edb5";
}
.icon-scan-2-line:before {
  content: "\edb6";
}
.icon-scan-line:before {
  content: "\edb7";
}
.icon-sd-card-line:before {
  content: "\edb8";
}
.icon-sd-card-mini-line:before {
  content: "\edb9";
}
.icon-sensor-line:before {
  content: "\edba";
}
.icon-server-line:before {
  content: "\edbb";
}
.icon-shut-down-line:before {
  content: "\edbc";
}
.icon-signal-wifi-1-line:before {
  content: "\edbd";
}
.icon-signal-wifi-2-line:before {
  content: "\edbe";
}
.icon-signal-wifi-3-line:before {
  content: "\edbf";
}
.icon-signal-wifi-error-line:before {
  content: "\edc0";
}
.icon-signal-wifi-line:before {
  content: "\edc1";
}
.icon-signal-wifi-off-line:before {
  content: "\edc2";
}
.icon-sim-card-2-line:before {
  content: "\edc3";
}
.icon-sim-card-line:before {
  content: "\edc4";
}
.icon-smartphone-line:before {
  content: "\edc5";
}
.icon-tablet-line:before {
  content: "\edc6";
}
.icon-tv-2-line:before {
  content: "\edc7";
}
.icon-tv-line:before {
  content: "\edc8";
}
.icon-u-disk-line:before {
  content: "\edc9";
}
.icon-uninstall-line:before {
  content: "\edca";
}
.icon-usb-line:before {
  content: "\edcb";
}
.icon-wifi-line:before {
  content: "\edcc";
}
.icon-wifi-off-line:before {
  content: "\edcd";
}
.icon-wireless-charging-line:before {
  content: "\edce";
}
.icon-braces-line:before {
  content: "\edcf";
}
.icon-brackets-line:before {
  content: "\edd0";
}
.icon-bug-2-line:before {
  content: "\edd1";
}
.icon-bug-line:before {
  content: "\edd2";
}
.icon-code-box-line:before {
  content: "\edd3";
}
.icon-code-line:before {
  content: "\edd4";
}
.icon-code-s-line:before {
  content: "\edd5";
}
.icon-code-s-slash-line:before {
  content: "\edd6";
}
.icon-command-line:before {
  content: "\edd7";
}
.icon-css3-line:before {
  content: "\edd8";
}
.icon-cursor-line:before {
  content: "\edd9";
}
.icon-git-branch-line:before {
  content: "\edda";
}
.icon-git-commit-line:before {
  content: "\eddb";
}
.icon-git-merge-line:before {
  content: "\eddc";
}
.icon-git-pull-request-line:before {
  content: "\eddd";
}
.icon-git-repository-commits-line:before {
  content: "\edde";
}
.icon-git-repository-line:before {
  content: "\eddf";
}
.icon-git-repository-private-line:before {
  content: "\ede0";
}
.icon-html5-line:before {
  content: "\ede1";
}
.icon-parentheses-line:before {
  content: "\ede2";
}
.icon-terminal-box-line:before {
  content: "\ede3";
}
.icon-terminal-line:before {
  content: "\ede4";
}
.icon-terminal-window-line:before {
  content: "\ede5";
}
.icon-anticlockwise-2-line:before {
  content: "\ede6";
}
.icon-anticlockwise-line:before {
  content: "\ede7";
}
.icon-artboard-2-line:before {
  content: "\ede8";
}
.icon-artboard-line:before {
  content: "\ede9";
}
.icon-ball-pen-line:before {
  content: "\edea";
}
.icon-blur-off-line:before {
  content: "\edeb";
}
.icon-brush-2-line:before {
  content: "\edec";
}
.icon-brush-3-line:before {
  content: "\eded";
}
.icon-brush-4-line:before {
  content: "\edee";
}
.icon-brush-line:before {
  content: "\edef";
}
.icon-clockwise-2-line:before {
  content: "\edf0";
}
.icon-clockwise-line:before {
  content: "\edf1";
}
.icon-collage-line:before {
  content: "\edf2";
}
.icon-compasses-2-line:before {
  content: "\edf3";
}
.icon-compasses-line:before {
  content: "\edf4";
}
.icon-contrast-2-line:before {
  content: "\edf5";
}
.icon-contrast-drop-2-line:before {
  content: "\edf6";
}
.icon-contrast-drop-line:before {
  content: "\edf7";
}
.icon-contrast-line:before {
  content: "\edf8";
}
.icon-crop-2-line:before {
  content: "\edf9";
}
.icon-crop-line:before {
  content: "\edfa";
}
.icon-drag-drop-line:before {
  content: "\edfb";
}
.icon-drag-move-2-line:before {
  content: "\edfc";
}
.icon-drag-move-line:before {
  content: "\edfd";
}
.icon-drop-line:before {
  content: "\edfe";
}
.icon-edit-2-line:before {
  content: "\edff";
}
.icon-edit-box-line:before {
  content: "\ee00";
}
.icon-edit-circle-line:before {
  content: "\ee01";
}
.icon-edit-line:before {
  content: "\ee02";
}
.icon-eraser-line:before {
  content: "\ee03";
}
.icon-focus-2-line:before {
  content: "\ee04";
}
.icon-focus-3-line:before {
  content: "\ee05";
}
.icon-focus-line:before {
  content: "\ee06";
}
.icon-grid-line:before {
  content: "\ee07";
}
.icon-hammer-line:before {
  content: "\ee08";
}
.icon-ink-bottle-line:before {
  content: "\ee09";
}
.icon-input-method-line:before {
  content: "\ee0a";
}
.icon-layout-2-line:before {
  content: "\ee0b";
}
.icon-layout-3-line:before {
  content: "\ee0c";
}
.icon-layout-4-line:before {
  content: "\ee0d";
}
.icon-layout-5-line:before {
  content: "\ee0e";
}
.icon-layout-6-line:before {
  content: "\ee0f";
}
.icon-layout-bottom-2-line:before {
  content: "\ee10";
}
.icon-layout-bottom-line:before {
  content: "\ee11";
}
.icon-layout-column-line:before {
  content: "\ee12";
}
.icon-layout-grid-line:before {
  content: "\ee13";
}
.icon-layout-left-2-line:before {
  content: "\ee14";
}
.icon-layout-left-line:before {
  content: "\ee15";
}
.icon-layout-line:before {
  content: "\ee16";
}
.icon-layout-masonry-line:before {
  content: "\ee17";
}
.icon-layout-right-2-line:before {
  content: "\ee18";
}
.icon-layout-right-line:before {
  content: "\ee19";
}
.icon-layout-row-line:before {
  content: "\ee1a";
}
.icon-layout-top-2-line:before {
  content: "\ee1b";
}
.icon-layout-top-line:before {
  content: "\ee1c";
}
.icon-magic-line:before {
  content: "\ee1d";
}
.icon-mark-pen-line:before {
  content: "\ee1e";
}
.icon-markup-line:before {
  content: "\ee1f";
}
.icon-paint-brush-line:before {
  content: "\ee20";
}
.icon-paint-line:before {
  content: "\ee21";
}
.icon-palette-line:before {
  content: "\ee22";
}
.icon-pantone-line:before {
  content: "\ee23";
}
.icon-pen-nib-line:before {
  content: "\ee24";
}
.icon-pencil-line:before {
  content: "\ee25";
}
.icon-pencil-ruler-2-line:before {
  content: "\ee26";
}
.icon-pencil-ruler-line:before {
  content: "\ee27";
}
.icon-quill-pen-line:before {
  content: "\ee28";
}
.icon-ruler-2-line:before {
  content: "\ee29";
}
.icon-ruler-line:before {
  content: "\ee2a";
}
.icon-scissors-2-line:before {
  content: "\ee2b";
}
.icon-scissors-cut-line:before {
  content: "\ee2c";
}
.icon-scissors-line:before {
  content: "\ee2d";
}
.icon-screenshot-2-line:before {
  content: "\ee2e";
}
.icon-screenshot-line:before {
  content: "\ee2f";
}
.icon-shape-2-line:before {
  content: "\ee30";
}
.icon-shape-line:before {
  content: "\ee31";
}
.icon-sip-line:before {
  content: "\ee32";
}
.icon-slice-line:before {
  content: "\ee33";
}
.icon-t-box-line:before {
  content: "\ee34";
}
.icon-table-alt-line:before {
  content: "\ee35";
}
.icon-table-line:before {
  content: "\ee36";
}
.icon-tools-line:before {
  content: "\ee37";
}
.icon-chat-1-line:before {
  content: "\ee38";
}
.icon-chat-2-line:before {
  content: "\ee39";
}
.icon-chat-3-line:before {
  content: "\ee3a";
}
.icon-chat-4-line:before {
  content: "\ee3b";
}
.icon-chat-check-line:before {
  content: "\ee3c";
}
.icon-chat-delete-line:before {
  content: "\ee3d";
}
.icon-chat-download-line:before {
  content: "\ee3e";
}
.icon-chat-follow-up-line:before {
  content: "\ee3f";
}
.icon-chat-forward-line:before {
  content: "\ee40";
}
.icon-chat-heart-line:before {
  content: "\ee41";
}
.icon-chat-history-line:before {
  content: "\ee42";
}
.icon-chat-new-line:before {
  content: "\ee43";
}
.icon-chat-off-line:before {
  content: "\ee44";
}
.icon-chat-poll-line:before {
  content: "\ee45";
}
.icon-chat-private-line:before {
  content: "\ee46";
}
.icon-chat-quote-line:before {
  content: "\ee47";
}
.icon-chat-settings-line:before {
  content: "\ee48";
}
.icon-chat-smile-2-line:before {
  content: "\ee49";
}
.icon-chat-smile-3-line:before {
  content: "\ee4a";
}
.icon-chat-smile-line:before {
  content: "\ee4b";
}
.icon-chat-upload-line:before {
  content: "\ee4c";
}
.icon-chat-voice-line:before {
  content: "\ee4d";
}
.icon-discuss-line:before {
  content: "\ee4e";
}
.icon-feedback-line:before {
  content: "\ee4f";
}
.icon-message-2-line:before {
  content: "\ee50";
}
.icon-message-3-line:before {
  content: "\ee51";
}
.icon-message-line:before {
  content: "\ee52";
}
.icon-question-answer-line:before {
  content: "\ee53";
}
.icon-questionnaire-line:before {
  content: "\ee54";
}
.icon-video-chat-line:before {
  content: "\ee55";
}
.icon-advertisement-line:before {
  content: "\ee56";
}
.icon-archive-drawer-line:before {
  content: "\ee57";
}
.icon-archive-line:before {
  content: "\ee58";
}
.icon-at-line:before {
  content: "\ee59";
}
.icon-attachment-line:before {
  content: "\ee5a";
}
.icon-award-line:before {
  content: "\ee5b";
}
.icon-bar-chart-2-line:before {
  content: "\ee5c";
}
.icon-bar-chart-box-line:before {
  content: "\ee5d";
}
.icon-bar-chart-grouped-line:before {
  content: "\ee5e";
}
.icon-bar-chart-horizontal-line:before {
  content: "\ee5f";
}
.icon-bar-chart-line:before {
  content: "\ee60";
}
.icon-bookmark-2-line:before {
  content: "\ee61";
}
.icon-bookmark-3-line:before {
  content: "\ee62";
}
.icon-bookmark-line:before {
  content: "\ee63";
}
.icon-briefcase-2-line:before {
  content: "\ee64";
}
.icon-briefcase-3-line:before {
  content: "\ee65";
}
.icon-briefcase-4-line:before {
  content: "\ee66";
}
.icon-briefcase-5-line:before {
  content: "\ee67";
}
.icon-briefcase-line:before {
  content: "\ee68";
}
.icon-bubble-chart-line:before {
  content: "\ee69";
}
.icon-calculator-line:before {
  content: "\ee6a";
}
.icon-calendar-2-line:before {
  content: "\ee6b";
}
.icon-calendar-check-line:before {
  content: "\ee6c";
}
.icon-calendar-event-line:before {
  content: "\ee6d";
}
.icon-calendar-line:before {
  content: "\ee6e";
}
.icon-calendar-todo-line:before {
  content: "\ee6f";
}
.icon-cloud-line:before {
  content: "\ee70";
}
.icon-cloud-off-line:before {
  content: "\ee71";
}
.icon-copyleft-line:before {
  content: "\ee72";
}
.icon-copyright-line:before {
  content: "\ee73";
}
.icon-creative-commons-by-line:before {
  content: "\ee74";
}
.icon-creative-commons-line:before {
  content: "\ee75";
}
.icon-creative-commons-nc-line:before {
  content: "\ee76";
}
.icon-creative-commons-nd-line:before {
  content: "\ee77";
}
.icon-creative-commons-sa-line:before {
  content: "\ee78";
}
.icon-creative-commons-zero-line:before {
  content: "\ee79";
}
.icon-customer-service-2-line:before {
  content: "\ee7a";
}
.icon-customer-service-line:before {
  content: "\ee7b";
}
.icon-donut-chart-fill:before {
  content: "\ee7c";
}
.icon-donut-chart-line:before {
  content: "\ee7d";
}
.icon-flag-2-line:before {
  content: "\ee7e";
}
.icon-flag-line:before {
  content: "\ee7f";
}
.icon-global-line:before {
  content: "\ee80";
}
.icon-honour-line:before {
  content: "\ee81";
}
.icon-inbox-archive-line:before {
  content: "\ee82";
}
.icon-inbox-line:before {
  content: "\ee83";
}
.icon-inbox-unarchive-line:before {
  content: "\ee84";
}
.icon-line-chart-line:before {
  content: "\ee85";
}
.icon-links-line:before {
  content: "\ee86";
}
.icon-mail-add-line:before {
  content: "\ee87";
}
.icon-mail-check-line:before {
  content: "\ee88";
}
.icon-mail-close-line:before {
  content: "\ee89";
}
.icon-mail-download-line:before {
  content: "\ee8a";
}
.icon-mail-forbid-line:before {
  content: "\ee8b";
}
.icon-mail-line:before {
  content: "\ee8c";
}
.icon-mail-lock-line:before {
  content: "\ee8d";
}
.icon-mail-open-line:before {
  content: "\ee8e";
}
.icon-mail-send-line:before {
  content: "\ee8f";
}
.icon-mail-settings-line:before {
  content: "\ee90";
}
.icon-mail-star-line:before {
  content: "\ee91";
}
.icon-mail-unread-line:before {
  content: "\ee92";
}
.icon-mail-volume-line:before {
  content: "\ee93";
}
.icon-medal-2-line:before {
  content: "\ee94";
}
.icon-medal-line:before {
  content: "\ee95";
}
.icon-pie-chart-2-line:before {
  content: "\ee96";
}
.icon-pie-chart-box-line:before {
  content: "\ee97";
}
.icon-pie-chart-line:before {
  content: "\ee98";
}
.icon-printer-cloud-line:before {
  content: "\ee99";
}
.icon-printer-line:before {
  content: "\ee9a";
}
.icon-profile-line:before {
  content: "\ee9b";
}
.icon-projector-2-line:before {
  content: "\ee9c";
}
.icon-projector-line:before {
  content: "\ee9d";
}
.icon-record-mail-line:before {
  content: "\ee9e";
}
.icon-registered-line:before {
  content: "\ee9f";
}
.icon-reply-all-line:before {
  content: "\eea0";
}
.icon-reply-line:before {
  content: "\eea1";
}
.icon-send-plane-2-line:before {
  content: "\eea2";
}
.icon-send-plane-line:before {
  content: "\eea3";
}
.icon-service-line:before {
  content: "\eea4";
}
.icon-slideshow-2-line:before {
  content: "\eea5";
}
.icon-slideshow-3-line:before {
  content: "\eea6";
}
.icon-slideshow-4-line:before {
  content: "\eea7";
}
.icon-slideshow-line:before {
  content: "\eea8";
}
.icon-stack-line:before {
  content: "\eea9";
}
.icon-trademark-line:before {
  content: "\eeaa";
}
.icon-window-2-line:before {
  content: "\eeab";
}
.icon-window-line:before {
  content: "\eeac";
}
.icon-ancient-gate-line:before {
  content: "\eead";
}
.icon-ancient-pavilion-line:before {
  content: "\eeae";
}
.icon-bank-line:before {
  content: "\eeaf";
}
.icon-building-2-line:before {
  content: "\eeb0";
}
.icon-building-3-line:before {
  content: "\eeb1";
}
.icon-building-4-line:before {
  content: "\eeb2";
}
.icon-building-line:before {
  content: "\eeb3";
}
.icon-community-line:before {
  content: "\eeb4";
}
.icon-government-line:before {
  content: "\eeb5";
}
.icon-home-2-line:before {
  content: "\eeb6";
}
.icon-home-3-line:before {
  content: "\eeb7";
}
.icon-home-4-line:before {
  content: "\eeb8";
}
.icon-home-5-line:before {
  content: "\eeb9";
}
.icon-home-6-line:before {
  content: "\eeba";
}
.icon-home-7-line:before {
  content: "\eebb";
}
.icon-home-8-line:before {
  content: "\eebc";
}
.icon-home-gear-line:before {
  content: "\eebd";
}
.icon-home-heart-line:before {
  content: "\eebe";
}
.icon-home-line:before {
  content: "\eebf";
}
.icon-home-smile-2-line:before {
  content: "\eec0";
}
.icon-home-smile-line:before {
  content: "\eec1";
}
.icon-home-wifi-line:before {
  content: "\eec2";
}
.icon-hospital-line:before {
  content: "\eec3";
}
.icon-hotel-line:before {
  content: "\eec4";
}
.icon-store-2-line:before {
  content: "\eec5";
}
.icon-store-3-line:before {
  content: "\eec6";
}
.icon-store-line:before {
  content: "\eec7";
}
.icon-home-2-fill:before {
  content: "\eec8";
}
.icon-home-3-fill:before {
  content: "\eec9";
}
.icon-home-8-fill:before {
  content: "\eeca";
}
.icon-home-smile-2-fill:before {
  content: "\eecb";
}
.icon-government-fill:before {
  content: "\eecc";
}
.icon-home-fill:before {
  content: "\eecd";
}
.icon-home-heart-fill:before {
  content: "\eece";
}
.icon-hospital-fill:before {
  content: "\eecf";
}
.icon-home-5-fill:before {
  content: "\eed0";
}
.icon-home-4-fill:before {
  content: "\eed1";
}
.icon-community-fill:before {
  content: "\eed2";
}
.icon-ancient-pavilion-fill:before {
  content: "\eed3";
}
.icon-ancient-gate-fill:before {
  content: "\eed4";
}
.icon-home-gear-fill:before {
  content: "\eed5";
}
.icon-bank-fill:before {
  content: "\eed6";
}
.icon-store-fill:before {
  content: "\eed7";
}
.icon-building-2-fill:before {
  content: "\eed8";
}
.icon-building-3-fill:before {
  content: "\eed9";
}
.icon-building-fill:before {
  content: "\eeda";
}
.icon-home-6-fill:before {
  content: "\eedb";
}
.icon-home-7-fill:before {
  content: "\eedc";
}
.icon-building-4-fill:before {
  content: "\eedd";
}
.icon-home-smile-fill:before {
  content: "\eede";
}
.icon-store-2-fill:before {
  content: "\eedf";
}
.icon-store-3-fill:before {
  content: "\eee0";
}
.icon-hotel-fill:before {
  content: "\eee1";
}
.icon-home-wifi-fill:before {
  content: "\eee2";
}
.icon-inbox-fill:before {
  content: "\eee3";
}
.icon-reply-all-fill:before {
  content: "\eee4";
}
.icon-creative-commons-nc-fill:before {
  content: "\eee5";
}
.icon-mail-star-fill:before {
  content: "\eee6";
}
.icon-slideshow-fill:before {
  content: "\eee7";
}
.icon-briefcase-4-fill:before {
  content: "\eee8";
}
.icon-briefcase-5-fill:before {
  content: "\eee9";
}
.icon-copyleft-fill:before {
  content: "\eeea";
}
.icon-links-fill:before {
  content: "\eeeb";
}
.icon-archive-drawer-fill:before {
  content: "\eeec";
}
.icon-pie-chart-fill:before {
  content: "\eeed";
}
.icon-printer-fill:before {
  content: "\eeee";
}
.icon-global-fill:before {
  content: "\eeef";
}
.icon-mail-volume-fill:before {
  content: "\eef0";
}
.icon-mail-add-fill:before {
  content: "\eef1";
}
.icon-trademark-fill:before {
  content: "\eef2";
}
.icon-mail-unread-fill:before {
  content: "\eef3";
}
.icon-bar-chart-fill:before {
  content: "\eef4";
}
.icon-send-plane-2-fill:before {
  content: "\eef5";
}
.icon-customer-service-fill:before {
  content: "\eef6";
}
.icon-medal-fill:before {
  content: "\eef7";
}
.icon-window-2-fill:before {
  content: "\eef8";
}
.icon-stack-fill:before {
  content: "\eef9";
}
.icon-bookmark-fill:before {
  content: "\eefa";
}
.icon-projector-2-fill:before {
  content: "\eefb";
}
.icon-award-fill:before {
  content: "\eefc";
}
.icon-mail-forbid-fill:before {
  content: "\eefd";
}
.icon-creative-commons-fill:before {
  content: "\eefe";
}
.icon-creative-commons-sa-fill:before {
  content: "\eeff";
}
.icon-bar-chart-box-fill:before {
  content: "\ef00";
}
.icon-bar-chart-horizontal-fill:before {
  content: "\ef01";
}
.icon-window-fill:before {
  content: "\ef02";
}
.icon-at-fill:before {
  content: "\ef03";
}
.icon-mail-send-fill:before {
  content: "\ef04";
}
.icon-profile-fill:before {
  content: "\ef05";
}
.icon-cloud-off-fill:before {
  content: "\ef06";
}
.icon-mail-check-fill:before {
  content: "\ef07";
}
.icon-advertisement-fill:before {
  content: "\ef08";
}
.icon-creative-commons-nd-fill:before {
  content: "\ef09";
}
.icon-briefcase-3-fill:before {
  content: "\ef0a";
}
.icon-briefcase-2-fill:before {
  content: "\ef0b";
}
.icon-printer-cloud-fill:before {
  content: "\ef0c";
}
.icon-bubble-chart-fill:before {
  content: "\ef0d";
}
.icon-calendar-check-fill:before {
  content: "\ef0e";
}
.icon-inbox-archive-fill:before {
  content: "\ef0f";
}
.icon-reply-fill:before {
  content: "\ef10";
}
.icon-bookmark-2-fill:before {
  content: "\ef11";
}
.icon-bookmark-3-fill:before {
  content: "\ef12";
}
.icon-bar-chart-2-fill:before {
  content: "\ef13";
}
.icon-creative-commons-zero-fill:before {
  content: "\ef14";
}
.icon-copyright-fill:before {
  content: "\ef15";
}
.icon-medal-2-fill:before {
  content: "\ef16";
}
.icon-mail-close-fill:before {
  content: "\ef17";
}
.icon-honour-fill:before {
  content: "\ef18";
}
.icon-mail-download-fill:before {
  content: "\ef19";
}
.icon-customer-service-2-fill:before {
  content: "\ef1a";
}
.icon-cloud-fill:before {
  content: "\ef1b";
}
.icon-line-chart-fill:before {
  content: "\ef1c";
}
.icon-slideshow-3-fill:before {
  content: "\ef1d";
}
.icon-slideshow-2-fill:before {
  content: "\ef1e";
}
.icon-creative-commons-by-fill:before {
  content: "\ef1f";
}
.icon-inbox-unarchive-fill:before {
  content: "\ef20";
}
.icon-calendar-2-fill:before {
  content: "\ef21";
}
.icon-projector-fill:before {
  content: "\ef22";
}
.icon-calendar-event-fill:before {
  content: "\ef23";
}
.icon-mail-open-fill:before {
  content: "\ef24";
}
.icon-pie-chart-2-fill:before {
  content: "\ef25";
}
.icon-attachment-fill:before {
  content: "\ef26";
}
.icon-calendar-fill:before {
  content: "\ef27";
}
.icon-mail-settings-fill:before {
  content: "\ef28";
}
.icon-pie-chart-box-fill:before {
  content: "\ef29";
}
.icon-send-plane-fill:before {
  content: "\ef2a";
}
.icon-flag-fill:before {
  content: "\ef2b";
}
.icon-flag-2-fill:before {
  content: "\ef2c";
}
.icon-calendar-todo-fill:before {
  content: "\ef2d";
}
.icon-slideshow-4-fill:before {
  content: "\ef2e";
}
.icon-calculator-fill:before {
  content: "\ef2f";
}
.icon-archive-fill:before {
  content: "\ef30";
}
.icon-mail-lock-fill:before {
  content: "\ef31";
}
.icon-service-fill:before {
  content: "\ef32";
}
.icon-registered-fill:before {
  content: "\ef33";
}
.icon-bar-chart-grouped-fill:before {
  content: "\ef34";
}
.icon-mail-fill:before {
  content: "\ef35";
}
.icon-record-mail-fill:before {
  content: "\ef36";
}
.icon-briefcase-fill:before {
  content: "\ef37";
}
.icon-chat-delete-fill:before {
  content: "\ef38";
}
.icon-chat-follow-up-fill:before {
  content: "\ef39";
}
.icon-chat-upload-fill:before {
  content: "\ef3a";
}
.icon-chat-voice-fill:before {
  content: "\ef3b";
}
.icon-chat-off-fill:before {
  content: "\ef3c";
}
.icon-chat-history-fill:before {
  content: "\ef3d";
}
.icon-chat-poll-fill:before {
  content: "\ef3e";
}
.icon-chat-heart-fill:before {
  content: "\ef3f";
}
.icon-chat-1-fill:before {
  content: "\ef40";
}
.icon-chat-smile-2-fill:before {
  content: "\ef41";
}
.icon-chat-smile-3-fill:before {
  content: "\ef42";
}
.icon-chat-quote-fill:before {
  content: "\ef43";
}
.icon-chat-4-fill:before {
  content: "\ef44";
}
.icon-chat-new-fill:before {
  content: "\ef45";
}
.icon-discuss-fill:before {
  content: "\ef46";
}
.icon-question-answer-fill:before {
  content: "\ef47";
}
.icon-chat-smile-fill:before {
  content: "\ef48";
}
.icon-chat-private-fill:before {
  content: "\ef49";
}
.icon-chat-download-fill:before {
  content: "\ef4a";
}
.icon-questionnaire-fill:before {
  content: "\ef4b";
}
.icon-feedback-fill:before {
  content: "\ef4c";
}
.icon-chat-forward-fill:before {
  content: "\ef4d";
}
.icon-message-2-fill:before {
  content: "\ef4e";
}
.icon-message-3-fill:before {
  content: "\ef4f";
}
.icon-video-chat-fill:before {
  content: "\ef50";
}
.icon-chat-settings-fill:before {
  content: "\ef51";
}
.icon-chat-3-fill:before {
  content: "\ef52";
}
.icon-chat-2-fill:before {
  content: "\ef53";
}
.icon-message-fill:before {
  content: "\ef54";
}
.icon-chat-check-fill:before {
  content: "\ef55";
}
.icon-layout-3-fill:before {
  content: "\ef56";
}
.icon-layout-2-fill:before {
  content: "\ef57";
}
.icon-layout-top-2-fill:before {
  content: "\ef58";
}
.icon-contrast-2-fill:before {
  content: "\ef59";
}
.icon-focus-fill:before {
  content: "\ef5a";
}
.icon-layout-left-fill:before {
  content: "\ef5b";
}
.icon-pencil-ruler-fill:before {
  content: "\ef5c";
}
.icon-layout-bottom-2-fill:before {
  content: "\ef5d";
}
.icon-grid-fill:before {
  content: "\ef5e";
}
.icon-slice-fill:before {
  content: "\ef5f";
}
.icon-scissors-fill:before {
  content: "\ef60";
}
.icon-edit-box-fill:before {
  content: "\ef61";
}
.icon-edit-fill:before {
  content: "\ef62";
}
.icon-layout-grid-fill:before {
  content: "\ef63";
}
.icon-anticlockwise-2-fill:before {
  content: "\ef64";
}
.icon-paint-brush-fill:before {
  content: "\ef65";
}
.icon-ink-bottle-fill:before {
  content: "\ef66";
}
.icon-edit-circle-fill:before {
  content: "\ef67";
}
.icon-mark-pen-fill:before {
  content: "\ef68";
}
.icon-clockwise-fill:before {
  content: "\ef69";
}
.icon-layout-right-2-fill:before {
  content: "\ef6a";
}
.icon-drag-drop-fill:before {
  content: "\ef6b";
}
.icon-layout-bottom-fill:before {
  content: "\ef6c";
}
.icon-drop-fill:before {
  content: "\ef6d";
}
.icon-magic-fill:before {
  content: "\ef6e";
}
.icon-layout-4-fill:before {
  content: "\ef6f";
}
.icon-layout-5-fill:before {
  content: "\ef70";
}
.icon-pen-nib-fill:before {
  content: "\ef71";
}
.icon-anticlockwise-fill:before {
  content: "\ef72";
}
.icon-crop-2-fill:before {
  content: "\ef73";
}
.icon-compasses-fill:before {
  content: "\ef74";
}
.icon-contrast-drop-fill:before {
  content: "\ef75";
}
.icon-compasses-2-fill:before {
  content: "\ef76";
}
.icon-table-alt-fill:before {
  content: "\ef77";
}
.icon-layout-top-fill:before {
  content: "\ef78";
}
.icon-pantone-fill:before {
  content: "\ef79";
}
.icon-clockwise-2-fill:before {
  content: "\ef7a";
}
.icon-t-box-fill:before {
  content: "\ef7b";
}
.icon-tools-fill:before {
  content: "\ef7c";
}
.icon-pencil-fill:before {
  content: "\ef7d";
}
.icon-scissors-cut-fill:before {
  content: "\ef7e";
}
.icon-quill-pen-fill:before {
  content: "\ef7f";
}
.icon-artboard-fill:before {
  content: "\ef80";
}
.icon-markup-fill:before {
  content: "\ef81";
}
.icon-brush-4-fill:before {
  content: "\ef82";
}
.icon-layout-masonry-fill:before {
  content: "\ef83";
}
.icon-paint-fill:before {
  content: "\ef84";
}
.icon-sip-fill:before {
  content: "\ef85";
}
.icon-input-method-fill:before {
  content: "\ef86";
}
.icon-artboard-2-fill:before {
  content: "\ef87";
}
.icon-shape-fill:before {
  content: "\ef88";
}
.icon-crop-fill:before {
  content: "\ef89";
}
.icon-drag-move-2-fill:before {
  content: "\ef8a";
}
.icon-collage-fill:before {
  content: "\ef8b";
}
.icon-contrast-drop-2-fill:before {
  content: "\ef8c";
}
.icon-edit-2-fill:before {
  content: "\ef8d";
}
.icon-focus-3-fill:before {
  content: "\ef8e";
}
.icon-focus-2-fill:before {
  content: "\ef8f";
}
.icon-shape-2-fill:before {
  content: "\ef90";
}
.icon-ruler-fill:before {
  content: "\ef91";
}
.icon-layout-row-fill:before {
  content: "\ef92";
}
.icon-layout-6-fill:before {
  content: "\ef93";
}
.icon-palette-fill:before {
  content: "\efac";
}
.icon-contrast-fill:before {
  content: "\efad";
}
.icon-eraser-fill:before {
  content: "\efae";
}
.icon-drag-move-fill:before {
  content: "\efaf";
}
.icon-ruler-2-fill:before {
  content: "\efb0";
}
.icon-ball-pen-fill:before {
  content: "\efb1";
}
.icon-layout-column-fill:before {
  content: "\efb2";
}
.icon-scissors-2-fill:before {
  content: "\efb3";
}
.icon-brush-fill:before {
  content: "\efb4";
}
.icon-layout-left-2-fill:before {
  content: "\efb5";
}
.icon-layout-fill:before {
  content: "\efb6";
}
.icon-screenshot-fill:before {
  content: "\efb7";
}
.icon-table-fill:before {
  content: "\efb8";
}
.icon-brush-2-fill:before {
  content: "\efb9";
}
.icon-brush-3-fill:before {
  content: "\efba";
}
.icon-hammer-fill:before {
  content: "\efbb";
}
.icon-screenshot-2-fill:before {
  content: "\efbc";
}
.icon-pencil-ruler-2-fill:before {
  content: "\efbd";
}
.icon-blur-off-fill:before {
  content: "\efbe";
}
.icon-layout-right-fill:before {
  content: "\efbf";
}
.icon-git-branch-fill:before {
  content: "\efc0";
}
.icon-braces-fill:before {
  content: "\efc1";
}
.icon-git-commit-fill:before {
  content: "\efc2";
}
.icon-css3-fill:before {
  content: "\efc3";
}
.icon-git-repository-commits-fill:before {
  content: "\efc4";
}
.icon-terminal-box-fill:before {
  content: "\efc5";
}
.icon-cursor-fill:before {
  content: "\efc6";
}
.icon-code-fill:before {
  content: "\efc7";
}
.icon-git-repository-private-fill:before {
  content: "\efc8";
}
.icon-command-fill:before {
  content: "\efc9";
}
.icon-git-repository-fill:before {
  content: "\efca";
}
.icon-git-merge-fill:before {
  content: "\efcb";
}
.icon-code-s-fill:before {
  content: "\efcc";
}
.icon-brackets-fill:before {
  content: "\efcd";
}
.icon-git-pull-request-fill:before {
  content: "\efce";
}
.icon-bug-fill:before {
  content: "\efcf";
}
.icon-code-box-fill:before {
  content: "\efd0";
}
.icon-terminal-window-fill:before {
  content: "\efd1";
}
.icon-html5-fill:before {
  content: "\efd2";
}
.icon-terminal-fill:before {
  content: "\efd3";
}
.icon-parentheses-fill:before {
  content: "\efd4";
}
.icon-bug-2-fill:before {
  content: "\efd5";
}
.icon-code-s-slash-fill:before {
  content: "\efd6";
}
.icon-gradienter-fill:before {
  content: "\efd7";
}
.icon-sd-card-fill:before {
  content: "\efd8";
}
.icon-battery-fill:before {
  content: "\efd9";
}
.icon-smartphone-fill:before {
  content: "\efda";
}
.icon-mac-fill:before {
  content: "\efdb";
}
.icon-device-recover-fill:before {
  content: "\efdc";
}
.icon-battery-charge-fill:before {
  content: "\efdd";
}
.icon-rss-fill:before {
  content: "\efde";
}
.icon-barcode-box-fill:before {
  content: "\efdf";
}
.icon-cellphone-fill:before {
  content: "\efe0";
}
.icon-bluetooth-fill:before {
  content: "\efe1";
}
.icon-gps-fill:before {
  content: "\efe2";
}
.icon-qr-scan-2-fill:before {
  content: "\efe3";
}
.icon-tv-2-fill:before {
  content: "\efe4";
}
.icon-tablet-fill:before {
  content: "\efe5";
}
.icon-remote-control-2-fill:before {
  content: "\efe6";
}
.icon-dual-sim-1-fill:before {
  content: "\efe7";
}
.icon-device-fill:before {
  content: "\efe8";
}
.icon-fingerprint-fill:before {
  content: "\efe9";
}
.icon-router-fill:before {
  content: "\efea";
}
.icon-restart-fill:before {
  content: "\efeb";
}
.icon-database-2-fill:before {
  content: "\efec";
}
.icon-scan-fill:before {
  content: "\efed";
}
.icon-sensor-fill:before {
  content: "\efee";
}
.icon-macbook-fill:before {
  content: "\efef";
}
.icon-save-2-fill:before {
  content: "\eff0";
}
.icon-save-3-fill:before {
  content: "\eff1";
}
.icon-install-fill:before {
  content: "\eff2";
}
.icon-signal-wifi-3-fill:before {
  content: "\eff3";
}
.icon-signal-wifi-2-fill:before {
  content: "\eff4";
}
.icon-rotate-lock-fill:before {
  content: "\eff5";
}
.icon-bluetooth-connect-fill:before {
  content: "\eff6";
}
.icon-qr-code-fill:before {
  content: "\eff7";
}
.icon-sim-card-2-fill:before {
  content: "\eff8";
}
.icon-shut-down-fill:before {
  content: "\eff9";
}
.icon-server-fill:before {
  content: "\effa";
}
.icon-qr-scan-fill:before {
  content: "\effb";
}
.icon-battery-share-fill:before {
  content: "\effc";
}
.icon-hard-drive-fill:before {
  content: "\effd";
}
.icon-battery-2-charge-fill:before {
  content: "\effe";
}
.icon-wifi-off-fill:before {
  content: "\efff";
}
.icon-dual-sim-2-fill:before {
  content: "\f000";
}
.icon-dashboard-3-fill:before {
  content: "\f001";
}
.icon-dashboard-2-fill:before {
  content: "\f002";
}
.icon-database-fill:before {
  content: "\f003";
}
.icon-radar-fill:before {
  content: "\f004";
}
.icon-signal-wifi-error-fill:before {
  content: "\f005";
}
.icon-save-fill:before {
  content: "\f006";
}
.icon-keyboard-fill:before {
  content: "\f007";
}
.icon-hard-drive-2-fill:before {
  content: "\f008";
}
.icon-wifi-fill:before {
  content: "\f009";
}
.icon-usb-fill:before {
  content: "\f00a";
}
.icon-barcode-fill:before {
  content: "\f00b";
}
.icon-fingerprint-2-fill:before {
  content: "\f00c";
}
.icon-phone-fill:before {
  content: "\f00d";
}
.icon-sim-card-fill:before {
  content: "\f00e";
}
.icon-base-station-fill:before {
  content: "\f00f";
}
.icon-battery-2-fill:before {
  content: "\f010";
}
.icon-signal-wifi-off-fill:before {
  content: "\f011";
}
.icon-hotspot-fill:before {
  content: "\f012";
}
.icon-wireless-charging-fill:before {
  content: "\f013";
}
.icon-phone-find-fill:before {
  content: "\f014";
}
.icon-computer-fill:before {
  content: "\f015";
}
.icon-signal-wifi-fill:before {
  content: "\f016";
}
.icon-sd-card-mini-fill:before {
  content: "\f017";
}
.icon-mouse-fill:before {
  content: "\f018";
}
.icon-keyboard-box-fill:before {
  content: "\f019";
}
.icon-phone-lock-fill:before {
  content: "\f01a";
}
.icon-scan-2-fill:before {
  content: "\f01b";
}
.icon-cast-fill:before {
  content: "\f01c";
}
.icon-gamepad-fill:before {
  content: "\f01d";
}
.icon-cpu-fill:before {
  content: "\f01e";
}
.icon-tv-fill:before {
  content: "\f01f";
}
.icon-signal-wifi-1-fill:before {
  content: "\f020";
}
.icon-uninstall-fill:before {
  content: "\f021";
}
.icon-airplay-fill:before {
  content: "\f022";
}
.icon-battery-low-fill:before {
  content: "\f023";
}
.icon-battery-saver-fill:before {
  content: "\f024";
}
.icon-remote-control-fill:before {
  content: "\f025";
}
.icon-u-disk-fill:before {
  content: "\f026";
}
.icon-folder-fill:before {
  content: "\f027";
}
.icon-file-excel-fill:before {
  content: "\f028";
}
.icon-folder-keyhole-fill:before {
  content: "\f029";
}
.icon-contacts-book-upload-fill:before {
  content: "\f02a";
}
.icon-file-shred-fill:before {
  content: "\f02b";
}
.icon-folder-3-fill:before {
  content: "\f02c";
}
.icon-folder-2-fill:before {
  content: "\f02d";
}
.icon-newspaper-fill:before {
  content: "\f02e";
}
.icon-file-code-fill:before {
  content: "\f02f";
}
.icon-folder-settings-fill:before {
  content: "\f030";
}
.icon-folder-warning-fill:before {
  content: "\f031";
}
.icon-file-paper-fill:before {
  content: "\f032";
}
.icon-file-cloud-fill:before {
  content: "\f033";
}
.icon-file-word-fill:before {
  content: "\f034";
}
.icon-file-mark-fill:before {
  content: "\f035";
}
.icon-folder-open-fill:before {
  content: "\f036";
}
.icon-file-ppt-2-fill:before {
  content: "\f037";
}
.icon-file-ppt-fill:before {
  content: "\f038";
}
.icon-file-pdf-fill:before {
  content: "\f039";
}
.icon-folder-chart-2-fill:before {
  content: "\f03a";
}
.icon-book-fill:before {
  content: "\f03b";
}
.icon-draft-fill:before {
  content: "\f03c";
}
.icon-contacts-book-fill:before {
  content: "\f03d";
}
.icon-file-chart-2-fill:before {
  content: "\f03e";
}
.icon-folder-music-fill:before {
  content: "\f03f";
}
.icon-article-fill:before {
  content: "\f040";
}
.icon-book-read-fill:before {
  content: "\f041";
}
.icon-file-download-fill:before {
  content: "\f042";
}
.icon-sticky-note-2-fill:before {
  content: "\f043";
}
.icon-pages-fill:before {
  content: "\f044";
}
.icon-file-warning-fill:before {
  content: "\f045";
}
.icon-folder-lock-fill:before {
  content: "\f046";
}
.icon-file-fill:before {
  content: "\f047";
}
.icon-task-fill:before {
  content: "\f048";
}
.icon-folder-user-fill:before {
  content: "\f049";
}
.icon-book-mark-fill:before {
  content: "\f04a";
}
.icon-folder-upload-fill:before {
  content: "\f04b";
}
.icon-book-2-fill:before {
  content: "\f04c";
}
.icon-book-3-fill:before {
  content: "\f04d";
}
.icon-folder-shared-fill:before {
  content: "\f04e";
}
.icon-folder-history-fill:before {
  content: "\f04f";
}
.icon-folder-download-fill:before {
  content: "\f050";
}
.icon-file-paper-2-fill:before {
  content: "\f051";
}
.icon-file-upload-fill:before {
  content: "\f052";
}
.icon-file-info-fill:before {
  content: "\f053";
}
.icon-file-excel-2-fill:before {
  content: "\f054";
}
.icon-file-edit-fill:before {
  content: "\f055";
}
.icon-file-search-fill:before {
  content: "\f056";
}
.icon-markdown-fill:before {
  content: "\f057";
}
.icon-file-history-fill:before {
  content: "\f058";
}
.icon-file-copy-2-fill:before {
  content: "\f059";
}
.icon-file-music-fill:before {
  content: "\f05a";
}
.icon-bill-fill:before {
  content: "\f05b";
}
.icon-folder-4-fill:before {
  content: "\f05c";
}
.icon-folder-5-fill:before {
  content: "\f05d";
}
.icon-file-settings-fill:before {
  content: "\f05e";
}
.icon-folders-fill:before {
  content: "\f05f";
}
.icon-file-hwp-fill:before {
  content: "\f060";
}
.icon-clipboard-fill:before {
  content: "\f061";
}
.icon-file-damage-fill:before {
  content: "\f062";
}
.icon-file-text-fill:before {
  content: "\f063";
}
.icon-book-open-fill:before {
  content: "\f064";
}
.icon-file-4-fill:before {
  content: "\f065";
}
.icon-file-gif-fill:before {
  content: "\f066";
}
.icon-sticky-note-fill:before {
  content: "\f067";
}
.icon-file-user-fill:before {
  content: "\f068";
}
.icon-file-transfer-fill:before {
  content: "\f069";
}
.icon-numbers-fill:before {
  content: "\f06a";
}
.icon-folder-info-fill:before {
  content: "\f06b";
}
.icon-contacts-book-2-fill:before {
  content: "\f06c";
}
.icon-file-unknow-fill:before {
  content: "\f06d";
}
.icon-folder-chart-fill:before {
  content: "\f06e";
}
.icon-folder-reduce-fill:before {
  content: "\f06f";
}
.icon-file-shield-2-fill:before {
  content: "\f070";
}
.icon-file-reduce-fill:before {
  content: "\f071";
}
.icon-file-word-2-fill:before {
  content: "\f072";
}
.icon-keynote-fill:before {
  content: "\f073";
}
.icon-folder-unknow-fill:before {
  content: "\f074";
}
.icon-file-list-3-fill:before {
  content: "\f075";
}
.icon-file-list-2-fill:before {
  content: "\f076";
}
.icon-folder-shield-fill:before {
  content: "\f077";
}
.icon-folder-forbid-fill:before {
  content: "\f078";
}
.icon-folder-zip-fill:before {
  content: "\f079";
}
.icon-folder-shield-2-fill:before {
  content: "\f07a";
}
.icon-folder-received-fill:before {
  content: "\f07b";
}
.icon-file-copy-fill:before {
  content: "\f07c";
}
.icon-folder-add-fill:before {
  content: "\f07d";
}
.icon-file-forbid-fill:before {
  content: "\f07e";
}
.icon-file-shield-fill:before {
  content: "\f07f";
}
.icon-booklet-fill:before {
  content: "\f080";
}
.icon-file-chart-fill:before {
  content: "\f081";
}
.icon-file-3-fill:before {
  content: "\f082";
}
.icon-file-2-fill:before {
  content: "\f083";
}
.icon-todo-fill:before {
  content: "\f084";
}
.icon-folder-transfer-fill:before {
  content: "\f085";
}
.icon-survey-fill:before {
  content: "\f086";
}
.icon-file-zip-fill:before {
  content: "\f087";
}
.icon-file-list-fill:before {
  content: "\f088";
}
.icon-file-add-fill:before {
  content: "\f089";
}
.icon-file-lock-fill:before {
  content: "\f08a";
}
.icon-safe-2-fill:before {
  content: "\f08b";
}
.icon-swap-box-fill:before {
  content: "\f08c";
}
.icon-money-pound-circle-fill:before {
  content: "\f08d";
}
.icon-swap-fill:before {
  content: "\f08e";
}
.icon-refund-fill:before {
  content: "\f08f";
}
.icon-money-cny-box-fill:before {
  content: "\f090";
}
.icon-exchange-box-fill:before {
  content: "\f091";
}
.icon-currency-fill:before {
  content: "\f092";
}
.icon-money-pound-box-fill:before {
  content: "\f093";
}
.icon-coupon-3-fill:before {
  content: "\f094";
}
.icon-coupon-2-fill:before {
  content: "\f095";
}
.icon-copper-coin-fill:before {
  content: "\f096";
}
.icon-shopping-basket-2-fill:before {
  content: "\f097";
}
.icon-bank-card-fill:before {
  content: "\f098";
}
.icon-coupon-fill:before {
  content: "\f099";
}
.icon-refund-2-fill:before {
  content: "\f09a";
}
.icon-gift-2-fill:before {
  content: "\f09b";
}
.icon-shopping-cart-2-fill:before {
  content: "\f09c";
}
.icon-money-cny-circle-fill:before {
  content: "\f09d";
}
.icon-price-tag-fill:before {
  content: "\f09e";
}
.icon-exchange-funds-line:before {
  content: "\f09f";
}
.icon-coupon-4-fill:before {
  content: "\f0a0";
}
.icon-coupon-5-fill:before {
  content: "\f0a1";
}
.icon-money-euro-circle-fill:before {
  content: "\f0a2";
}
.icon-coin-fill:before {
  content: "\f0a3";
}
.icon-coins-fill:before {
  content: "\f0a4";
}
.icon-bank-card-2-fill:before {
  content: "\f0a5";
}
.icon-shopping-bag-fill:before {
  content: "\f0a6";
}
.icon-ticket-fill:before {
  content: "\f0a7";
}
.icon-exchange-fill:before {
  content: "\f0a8";
}
.icon-vip-crown-2-fill:before {
  content: "\f0a9";
}
.icon-gift-fill:before {
  content: "\f0aa";
}
.icon-safe-fill:before {
  content: "\f0ab";
}
.icon-money-dollar-box-fill:before {
  content: "\f0ac";
}
.icon-shopping-cart-fill:before {
  content: "\f0ad";
}
.icon-funds-fill:before {
  content: "\f0ae";
}
.icon-red-packet-fill:before {
  content: "\f0af";
}
.icon-percent-fill:before {
  content: "\f0b0";
}
.icon-vip-fill:before {
  content: "\f0b1";
}
.icon-copper-diamond-fill:before {
  content: "\f0b2";
}
.icon-increase-decrease-fill:before {
  content: "\f0b3";
}
.icon-funds-box-fill:before {
  content: "\f0b4";
}
.icon-price-tag-3-fill:before {
  content: "\f0b5";
}
.icon-price-tag-2-fill:before {
  content: "\f0b6";
}
.icon-secure-payment-fill:before {
  content: "\f0b7";
}
.icon-vip-crown-fill:before {
  content: "\f0b8";
}
.icon-hand-heart-fill:before {
  content: "\f0b9";
}
.icon-shopping-basket-fill:before {
  content: "\f0ba";
}
.icon-bit-coin-fill:before {
  content: "\f0bb";
}
.icon-hours-fill:before {
  content: "\f0bc";
}
.icon-stock-fill:before {
  content: "\f0bd";
}
.icon-shopping-bag-2-fill:before {
  content: "\f0be";
}
.icon-shopping-bag-3-fill:before {
  content: "\f0bf";
}
.icon-ticket-2-fill:before {
  content: "\f0c0";
}
.icon-trophy-fill:before {
  content: "\f0c1";
}
.icon-hand-coin-fill:before {
  content: "\f0c2";
}
.icon-money-dollar-circle-fill:before {
  content: "\f0c3";
}
.icon-exchange-dollar-fill:before {
  content: "\f0c4";
}
.icon-wallet-fill:before {
  content: "\f0c5";
}
.icon-auction-fill:before {
  content: "\f0c6";
}
.icon-water-flash-fill:before {
  content: "\f0c7";
}
.icon-wallet-2-fill:before {
  content: "\f0c8";
}
.icon-wallet-3-fill:before {
  content: "\f0c9";
}
.icon-vip-diamond-fill:before {
  content: "\f0ca";
}
.icon-exchange-cny-fill:before {
  content: "\f0cb";
}
.icon-money-euro-box-fill:before {
  content: "\f0cc";
}
.icon-medicine-bottle-fill:before {
  content: "\f0cd";
}
.icon-first-aid-kit-fill:before {
  content: "\f0ce";
}
.icon-hearts-fill:before {
  content: "\f0cf";
}
.icon-zzz-fill:before {
  content: "\f0d0";
}
.icon-dislike-fill:before {
  content: "\f0d1";
}
.icon-thermometer-fill:before {
  content: "\f0d2";
}
.icon-stethoscope-fill:before {
  content: "\f0d3";
}
.icon-lungs-fill:before {
  content: "\f0d4";
}
.icon-flask-fill:before {
  content: "\f0d5";
}
.icon-nurse-fill:before {
  content: "\f0d6";
}
.icon-capsule-fill:before {
  content: "\f0d7";
}
.icon-empathize-fill:before {
  content: "\f0d8";
}
.icon-heart-add-fill:before {
  content: "\f0d9";
}
.icon-mental-health-fill:before {
  content: "\f0da";
}
.icon-syringe-fill:before {
  content: "\f0db";
}
.icon-pulse-fill:before {
  content: "\f0dc";
}
.icon-psychotherapy-fill:before {
  content: "\f0dd";
}
.icon-dossier-fill:before {
  content: "\f0de";
}
.icon-virus-fill:before {
  content: "\f0df";
}
.icon-heart-fill:before {
  content: "\f0e0";
}
.icon-infrared-thermometer-fill:before {
  content: "\f0e1";
}
.icon-health-book-fill:before {
  content: "\f0e2";
}
.icon-test-tube-fill:before {
  content: "\f0e3";
}
.icon-heart-pulse-fill:before {
  content: "\f0e4";
}
.icon-hand-sanitizer-fill:before {
  content: "\f0e5";
}
.icon-microscope-fill:before {
  content: "\f0e6";
}
.icon-rest-time-fill:before {
  content: "\f0e7";
}
.icon-surgical-mask-fill:before {
  content: "\f0e8";
}
.icon-heart-3-fill:before {
  content: "\f0e9";
}
.icon-heart-2-fill:before {
  content: "\f0ea";
}
.icon-unsplash-fill:before {
  content: "\f0eb";
}
.icon-skype-fill:before {
  content: "\f0ec";
}
.icon-tumblr-fill:before {
  content: "\f0ed";
}
.icon-google-play-fill:before {
  content: "\f0ee";
}
.icon-paypal-fill:before {
  content: "\f0ef";
}
.icon-dingding-fill:before {
  content: "\f0f0";
}
.icon-switch-fill:before {
  content: "\f0f1";
}
.icon-facebook-box-fill:before {
  content: "\f0f2";
}
.icon-edge-fill:before {
  content: "\f0f3";
}
.icon-alipay-fill:before {
  content: "\f0f4";
}
.icon-reddit-fill:before {
  content: "\f0f5";
}
.icon-drive-fill:before {
  content: "\f0f6";
}
.icon-taobao-fill:before {
  content: "\f0f7";
}
.icon-linkedin-fill:before {
  content: "\f0f8";
}
.icon-npmjs-fill:before {
  content: "\f0f9";
}
.icon-vuejs-fill:before {
  content: "\f0fa";
}
.icon-android-fill:before {
  content: "\f0fb";
}
.icon-youtube-fill:before {
  content: "\f0fc";
}
.icon-kakao-talk-fill:before {
  content: "\f0fd";
}
.icon-firefox-fill:before {
  content: "\f0fe";
}
.icon-reactjs-fill:before {
  content: "\f0ff";
}
.icon-twitch-fill:before {
  content: "\f100";
}
.icon-dropbox-fill:before {
  content: "\f101";
}
.icon-instagram-fill:before {
  content: "\f102";
}
.icon-messenger-fill:before {
  content: "\f103";
}
.icon-bilibili-fill:before {
  content: "\f104";
}
.icon-steam-fill:before {
  content: "\f105";
}
.icon-remixicon-fill:before {
  content: "\f106";
}
.icon-gitlab-fill:before {
  content: "\f107";
}
.icon-wechat-fill:before {
  content: "\f108";
}
.icon-opera-fill:before {
  content: "\f109";
}
.icon-stack-overflow-fill:before {
  content: "\f10a";
}
.icon-snapchat-fill:before {
  content: "\f10b";
}
.icon-open-source-fill:before {
  content: "\f10c";
}
.icon-wechat-pay-fill:before {
  content: "\f10d";
}
.icon-mini-program-fill:before {
  content: "\f10e";
}
.icon-telegram-fill:before {
  content: "\f10f";
}
.icon-gatsby-fill:before {
  content: "\f110";
}
.icon-google-fill:before {
  content: "\f111";
}
.icon-windows-fill:before {
  content: "\f112";
}
.icon-ubuntu-fill:before {
  content: "\f113";
}
.icon-ie-fill:before {
  content: "\f114";
}
.icon-facebook-circle-fill:before {
  content: "\f115";
}
.icon-vimeo-fill:before {
  content: "\f116";
}
.icon-behance-fill:before {
  content: "\f117";
}
.icon-product-hunt-fill:before {
  content: "\f118";
}
.icon-invision-fill:before {
  content: "\f119";
}
.icon-pinterest-fill:before {
  content: "\f11a";
}
.icon-douban-fill:before {
  content: "\f11b";
}
.icon-qq-fill:before {
  content: "\f11c";
}
.icon-wechat-2-fill:before {
  content: "\f11d";
}
.icon-xbox-fill:before {
  content: "\f11e";
}
.icon-playstation-fill:before {
  content: "\f11f";
}
.icon-mastercard-fill:before {
  content: "\f120";
}
.icon-medium-fill:before {
  content: "\f121";
}
.icon-dribbble-fill:before {
  content: "\f122";
}
.icon-baidu-fill:before {
  content: "\f123";
}
.icon-coreos-fill:before {
  content: "\f124";
}
.icon-whatsapp-fill:before {
  content: "\f125";
}
.icon-netflix-fill:before {
  content: "\f126";
}
.icon-twitter-fill:before {
  content: "\f127";
}
.icon-mastodon-fill:before {
  content: "\f128";
}
.icon-github-fill:before {
  content: "\f129";
}
.icon-safari-fill:before {
  content: "\f12a";
}
.icon-spotify-fill:before {
  content: "\f12b";
}
.icon-spectrum-fill:before {
  content: "\f12c";
}
.icon-line-fill:before {
  content: "\f12d";
}
.icon-weibo-fill:before {
  content: "\f12e";
}
.icon-evernote-fill:before {
  content: "\f12f";
}
.icon-visa-fill:before {
  content: "\f130";
}
.icon-amazon-fill:before {
  content: "\f131";
}
.icon-slack-fill:before {
  content: "\f132";
}
.icon-stackshare-fill:before {
  content: "\f133";
}
.icon-facebook-fill:before {
  content: "\f134";
}
.icon-discord-fill:before {
  content: "\f135";
}
.icon-xing-fill:before {
  content: "\f136";
}
.icon-netease-cloud-music-fill:before {
  content: "\f137";
}
.icon-centos-fill:before {
  content: "\f138";
}
.icon-pixelfed-fill:before {
  content: "\f139";
}
.icon-disqus-fill:before {
  content: "\f13a";
}
.icon-apple-fill:before {
  content: "\f13b";
}
.icon-zhihu-fill:before {
  content: "\f13c";
}
.icon-trello-fill:before {
  content: "\f13d";
}
.icon-angularjs-fill:before {
  content: "\f13e";
}
.icon-honor-of-kings-fill:before {
  content: "\f13f";
}
.icon-zcool-fill:before {
  content: "\f140";
}
.icon-soundcloud-fill:before {
  content: "\f141";
}
.icon-linkedin-box-fill:before {
  content: "\f142";
}
.icon-app-store-fill:before {
  content: "\f143";
}
.icon-finder-fill:before {
  content: "\f144";
}
.icon-codepen-fill:before {
  content: "\f145";
}
.icon-chrome-fill:before {
  content: "\f146";
}
.icon-flutter-fill:before {
  content: "\f147";
}
.icon-patreon-fill:before {
  content: "\f148";
}
.icon-microsoft-fill:before {
  content: "\f149";
}
.icon-flight-takeoff-fill:before {
  content: "\f14a";
}
.icon-map-pin-range-fill:before {
  content: "\f14b";
}
.icon-hotel-bed-fill:before {
  content: "\f14c";
}
.icon-direction-fill:before {
  content: "\f14d";
}
.icon-compass-2-fill:before {
  content: "\f14e";
}
.icon-compass-3-fill:before {
  content: "\f14f";
}
.icon-car-washing-fill:before {
  content: "\f150";
}
.icon-police-car-fill:before {
  content: "\f151";
}
.icon-flight-land-fill:before {
  content: "\f152";
}
.icon-car-fill:before {
  content: "\f153";
}
.icon-truck-fill:before {
  content: "\f154";
}
.icon-lifebuoy-fill:before {
  content: "\f155";
}
.icon-subway-wifi-fill:before {
  content: "\f156";
}
.icon-navigation-fill:before {
  content: "\f157";
}
.icon-map-2-fill:before {
  content: "\f158";
}
.icon-taxi-wifi-fill:before {
  content: "\f159";
}
.icon-ship-2-fill:before {
  content: "\f15a";
}
.icon-restaurant-fill:before {
  content: "\f15b";
}
.icon-charging-pile-2-fill:before {
  content: "\f15c";
}
.icon-map-pin-4-fill:before {
  content: "\f15d";
}
.icon-map-pin-5-fill:before {
  content: "\f15e";
}
.icon-compass-fill:before {
  content: "\f15f";
}
.icon-gas-station-fill:before {
  content: "\f160";
}
.icon-restaurant-2-fill:before {
  content: "\f161";
}
.icon-ship-fill:before {
  content: "\f162";
}
.icon-walk-fill:before {
  content: "\f163";
}
.icon-goblet-fill:before {
  content: "\f164";
}
.icon-guide-fill:before {
  content: "\f165";
}
.icon-map-pin-3-fill:before {
  content: "\f166";
}
.icon-map-pin-2-fill:before {
  content: "\f167";
}
.icon-pushpin-2-fill:before {
  content: "\f168";
}
.icon-map-fill:before {
  content: "\f169";
}
.icon-steering-fill:before {
  content: "\f16a";
}
.icon-passport-fill:before {
  content: "\f16b";
}
.icon-takeaway-fill:before {
  content: "\f16c";
}
.icon-oil-fill:before {
  content: "\f16d";
}
.icon-pin-distance-fill:before {
  content: "\f16e";
}
.icon-china-railway-fill:before {
  content: "\f16f";
}
.icon-compass-4-fill:before {
  content: "\f170";
}
.icon-train-wifi-fill:before {
  content: "\f171";
}
.icon-riding-fill:before {
  content: "\f172";
}
.icon-run-fill:before {
  content: "\f173";
}
.icon-motorbike-fill:before {
  content: "\f174";
}
.icon-roadster-fill:before {
  content: "\f175";
}
.icon-bus-wifi-fill:before {
  content: "\f176";
}
.icon-anchor-fill:before {
  content: "\f177";
}
.icon-bus-2-fill:before {
  content: "\f178";
}
.icon-map-pin-time-fill:before {
  content: "\f179";
}
.icon-e-bike-fill:before {
  content: "\f17a";
}
.icon-treasure-map-fill:before {
  content: "\f17b";
}
.icon-rocket-fill:before {
  content: "\f17c";
}
.icon-charging-pile-fill:before {
  content: "\f17d";
}
.icon-barricade-fill:before {
  content: "\f17e";
}
.icon-bike-fill:before {
  content: "\f17f";
}
.icon-road-map-fill:before {
  content: "\f180";
}
.icon-subway-fill:before {
  content: "\f181";
}
.icon-luggage-cart-fill:before {
  content: "\f182";
}
.icon-signal-tower-fill:before {
  content: "\f183";
}
.icon-route-fill:before {
  content: "\f184";
}
.icon-suitcase-2-fill:before {
  content: "\f185";
}
.icon-suitcase-3-fill:before {
  content: "\f186";
}
.icon-e-bike-2-fill:before {
  content: "\f187";
}
.icon-space-ship-fill:before {
  content: "\f188";
}
.icon-rocket-2-fill:before {
  content: "\f189";
}
.icon-footprint-fill:before {
  content: "\f18a";
}
.icon-traffic-light-fill:before {
  content: "\f18b";
}
.icon-map-pin-user-fill:before {
  content: "\f18c";
}
.icon-parking-fill:before {
  content: "\f18d";
}
.icon-taxi-fill:before {
  content: "\f18e";
}
.icon-suitcase-fill:before {
  content: "\f18f";
}
.icon-steering-2-fill:before {
  content: "\f190";
}
.icon-earth-fill:before {
  content: "\f191";
}
.icon-pushpin-fill:before {
  content: "\f192";
}
.icon-caravan-fill:before {
  content: "\f193";
}
.icon-map-pin-add-fill:before {
  content: "\f194";
}
.icon-plane-fill:before {
  content: "\f195";
}
.icon-train-fill:before {
  content: "\f196";
}
.icon-cup-fill:before {
  content: "\f197";
}
.icon-map-pin-fill:before {
  content: "\f198";
}
.icon-sailboat-fill:before {
  content: "\f199";
}
.icon-luggage-deposit-fill:before {
  content: "\f19a";
}
.icon-globe-fill:before {
  content: "\f19b";
}
.icon-bus-fill:before {
  content: "\f19c";
}
.icon-compass-discover-fill:before {
  content: "\f19d";
}
.icon-parking-box-fill:before {
  content: "\f19e";
}
.icon-dv-fill:before {
  content: "\f19f";
}
.icon-music-2-fill:before {
  content: "\f1a0";
}
.icon-speaker-2-fill:before {
  content: "\f1a1";
}
.icon-speaker-3-fill:before {
  content: "\f1a2";
}
.icon-volume-off-vibrate-fill:before {
  content: "\f1a3";
}
.icon-closed-captioning-fill:before {
  content: "\f1a4";
}
.icon-stop-mini-fill:before {
  content: "\f1a5";
}
.icon-picture-in-picture-2-fill:before {
  content: "\f1a6";
}
.icon-fullscreen-fill:before {
  content: "\f1a7";
}
.icon-skip-forward-fill:before {
  content: "\f1a8";
}
.icon-hd-fill:before {
  content: "\f1a9";
}
.icon-skip-back-fill:before {
  content: "\f1aa";
}
.icon-stop-fill:before {
  content: "\f1ab";
}
.icon-speaker-fill:before {
  content: "\f1ac";
}
.icon-image-edit-fill:before {
  content: "\f1ad";
}
.icon-notification-off-fill:before {
  content: "\f1ae";
}
.icon-hq-fill:before {
  content: "\f1af";
}
.icon-shuffle-fill:before {
  content: "\f1b0";
}
.icon-surround-sound-fill:before {
  content: "\f1b1";
}
.icon-repeat-2-fill:before {
  content: "\f1b2";
}
.icon-record-circle-fill:before {
  content: "\f1b3";
}
.icon-stop-circle-fill:before {
  content: "\f1b4";
}
.icon-equalizer-fill:before {
  content: "\f1b5";
}
.icon-picture-in-picture-fill:before {
  content: "\f1b6";
}
.icon-gallery-upload-fill:before {
  content: "\f1b7";
}
.icon-voiceprint-fill:before {
  content: "\f1b8";
}
.icon-fullscreen-exit-fill:before {
  content: "\f1b9";
}
.icon-camera-switch-fill:before {
  content: "\f1ba";
}
.icon-rhythm-fill:before {
  content: "\f1bb";
}
.icon-tape-fill:before {
  content: "\f1bc";
}
.icon-pause-mini-fill:before {
  content: "\f1bd";
}
.icon-image-2-fill:before {
  content: "\f1be";
}
.icon-play-circle-fill:before {
  content: "\f1bf";
}
.icon-pause-circle-fill:before {
  content: "\f1c0";
}
.icon-webcam-fill:before {
  content: "\f1c1";
}
.icon-play-mini-fill:before {
  content: "\f1c2";
}
.icon-dvd-fill:before {
  content: "\f1c3";
}
.icon-radio-2-fill:before {
  content: "\f1c4";
}
.icon-play-list-add-fill:before {
  content: "\f1c5";
}
.icon-camera-fill:before {
  content: "\f1c6";
}
.icon-eject-fill:before {
  content: "\f1c7";
}
.icon-volume-vibrate-fill:before {
  content: "\f1c8";
}
.icon-image-add-fill:before {
  content: "\f1c9";
}
.icon-gallery-fill:before {
  content: "\f1ca";
}
.icon-disc-fill:before {
  content: "\f1cb";
}
.icon-volume-mute-fill:before {
  content: "\f1cc";
}
.icon-landscape-fill:before {
  content: "\f1cd";
}
.icon-mic-2-fill:before {
  content: "\f1ce";
}
.icon-mic-off-fill:before {
  content: "\f1cf";
}
.icon-repeat-fill:before {
  content: "\f1d0";
}
.icon-picture-in-picture-exit-fill:before {
  content: "\f1d1";
}
.icon-camera-off-fill:before {
  content: "\f1d2";
}
.icon-play-list-fill:before {
  content: "\f1d3";
}
.icon-polaroid-fill:before {
  content: "\f1d4";
}
.icon-camera-2-fill:before {
  content: "\f1d5";
}
.icon-camera-3-fill:before {
  content: "\f1d6";
}
.icon-vidicon-fill:before {
  content: "\f1d7";
}
.icon-movie-2-fill:before {
  content: "\f1d8";
}
.icon-movie-fill:before {
  content: "\f1d9";
}
.icon-polaroid-2-fill:before {
  content: "\f1da";
}
.icon-notification-3-fill:before {
  content: "\f1db";
}
.icon-notification-2-fill:before {
  content: "\f1dc";
}
.icon-mv-fill:before {
  content: "\f1dd";
}
.icon-rewind-fill:before {
  content: "\f1de";
}
.icon-play-list-2-fill:before {
  content: "\f1df";
}
.icon-live-fill:before {
  content: "\f1e0";
}
.icon-sound-module-fill:before {
  content: "\f1e1";
}
.icon-rewind-mini-fill:before {
  content: "\f1e2";
}
.icon-broadcast-fill:before {
  content: "\f1e3";
}
.icon-video-add-fill:before {
  content: "\f1e4";
}
.icon-volume-down-fill:before {
  content: "\f1e5";
}
.icon-speed-fill:before {
  content: "\f1e6";
}
.icon-clapperboard-fill:before {
  content: "\f1e7";
}
.icon-speed-mini-fill:before {
  content: "\f1e8";
}
.icon-order-play-fill:before {
  content: "\f1e9";
}
.icon-notification-4-fill:before {
  content: "\f1ea";
}
.icon-k-fill:before {
  content: "\f1eb";
}
.icon-skip-back-mini-fill:before {
  content: "\f1ec";
}
.icon-video-download-fill:before {
  content: "\f1ed";
}
.icon-phone-camera-fill:before {
  content: "\f1ee";
}
.icon-aspect-ratio-fill:before {
  content: "\f1ef";
}
.icon-music-fill:before {
  content: "\f1f0";
}
.icon-video-fill:before {
  content: "\f1f1";
}
.icon-pause-fill:before {
  content: "\f1f2";
}
.icon-repeat-one-fill:before {
  content: "\f1f3";
}
.icon-notification-fill:before {
  content: "\f1f4";
}
.icon-mic-fill:before {
  content: "\f1f5";
}
.icon-album-fill:before {
  content: "\f1f6";
}
.icon-skip-forward-mini-fill:before {
  content: "\f1f7";
}
.icon-film-fill:before {
  content: "\f1f8";
}
.icon-video-upload-fill:before {
  content: "\f1f9";
}
.icon-volume-up-fill:before {
  content: "\f1fa";
}
.icon-image-fill:before {
  content: "\f1fb";
}
.icon-vidicon-2-fill:before {
  content: "\f1fc";
}
.icon-radio-fill:before {
  content: "\f1fd";
}
.icon-headphone-fill:before {
  content: "\f1fe";
}
.icon-play-fill:before {
  content: "\f1ff";
}
.icon-camera-lens-line:before {
  content: "\f200";
}
.icon-outlet-fill:before {
  content: "\f201";
}
.icon-key-2-fill:before {
  content: "\f202";
}
.icon-door-fill:before {
  content: "\f203";
}
.icon-knife-fill:before {
  content: "\f204";
}
.icon-cactus-fill:before {
  content: "\f205";
}
.icon-plant-fill:before {
  content: "\f206";
}
.icon-scales-2-fill:before {
  content: "\f207";
}
.icon-scales-3-fill:before {
  content: "\f208";
}
.icon-plug-2-fill:before {
  content: "\f209";
}
.icon-lightbulb-fill:before {
  content: "\f20a";
}
.icon-voice-recognition-fill:before {
  content: "\f20b";
}
.icon-cake-fill:before {
  content: "\f20c";
}
.icon-leaf-fill:before {
  content: "\f20d";
}
.icon-lightbulb-flash-fill:before {
  content: "\f20e";
}
.icon-door-lock-box-fill:before {
  content: "\f20f";
}
.icon-cake-3-fill:before {
  content: "\f210";
}
.icon-cake-2-fill:before {
  content: "\f211";
}
.icon-seedling-fill:before {
  content: "\f212";
}
.icon-umbrella-fill:before {
  content: "\f213";
}
.icon-wheelchair-fill:before {
  content: "\f214";
}
.icon-knife-blood-fill:before {
  content: "\f215";
}
.icon-plug-fill:before {
  content: "\f216";
}
.icon-outlet-2-fill:before {
  content: "\f217";
}
.icon-key-fill:before {
  content: "\f218";
}
.icon-door-closed-fill:before {
  content: "\f219";
}
.icon-character-recognition-fill:before {
  content: "\f21a";
}
.icon-door-open-fill:before {
  content: "\f21b";
}
.icon-reserved-fill:before {
  content: "\f21c";
}
.icon-game-fill:before {
  content: "\f21d";
}
.icon-t-shirt-2-fill:before {
  content: "\f21e";
}
.icon-basketball-fill:before {
  content: "\f21f";
}
.icon-football-fill:before {
  content: "\f220";
}
.icon-door-lock-fill:before {
  content: "\f221";
}
.icon-handbag-fill:before {
  content: "\f222";
}
.icon-sword-fill:before {
  content: "\f223";
}
.icon-fridge-fill:before {
  content: "\f224";
}
.icon-boxing-fill:before {
  content: "\f225";
}
.icon-recycle-fill:before {
  content: "\f226";
}
.icon-bell-fill:before {
  content: "\f227";
}
.icon-ping-pong-fill:before {
  content: "\f228";
}
.icon-t-shirt-fill:before {
  content: "\f229";
}
.icon-t-shirt-air-fill:before {
  content: "\f22a";
}
.icon-scales-fill:before {
  content: "\f22b";
}
.icon-billiards-fill:before {
  content: "\f22c";
}
.icon-shirt-fill:before {
  content: "\f22d";
}
.icon-filter-off-fill:before {
  content: "\f22e";
}
.icon-arrow-right-down-fill:before {
  content: "\f22f";
}
.icon-divide-fill:before {
  content: "\f230";
}
.icon-delete-bin-2-fill:before {
  content: "\f231";
}
.icon-delete-bin-3-fill:before {
  content: "\f232";
}
.icon-share-circle-fill:before {
  content: "\f233";
}
.icon-arrow-right-fill:before {
  content: "\f234";
}
.icon-upload-cloud-fill:before {
  content: "\f235";
}
.icon-search-fill:before {
  content: "\f236";
}
.icon-add-box-fill:before {
  content: "\f237";
}
.icon-download-cloud-2-fill:before {
  content: "\f238";
}
.icon-checkbox-multiple-blank-fill:before {
  content: "\f239";
}
.icon-logout-box-fill:before {
  content: "\f23a";
}
.icon-lock-2-fill:before {
  content: "\f23b";
}
.icon-upload-2-fill:before {
  content: "\f23c";
}
.icon-menu-3-fill:before {
  content: "\f23d";
}
.icon-menu-2-fill:before {
  content: "\f23e";
}
.icon-logout-circle-r-fill:before {
  content: "\f23f";
}
.icon-settings-fill:before {
  content: "\f240";
}
.icon-arrow-drop-left-fill:before {
  content: "\f241";
}
.icon-arrow-up-fill:before {
  content: "\f242";
}
.icon-share-fill:before {
  content: "\f243";
}
.icon-error-warning-fill:before {
  content: "\f244";
}
.icon-add-fill:before {
  content: "\f245";
}
.icon-shield-flash-fill:before {
  content: "\f246";
}
.icon-upload-fill:before {
  content: "\f247";
}
.icon-checkbox-blank-fill:before {
  content: "\f248";
}
.icon-login-circle-fill:before {
  content: "\f249";
}
.icon-zoom-in-fill:before {
  content: "\f24a";
}
.icon-star-s-fill:before {
  content: "\f24b";
}
.icon-search-eye-fill:before {
  content: "\f24c";
}
.icon-arrow-down-s-fill:before {
  content: "\f24d";
}
.icon-timer-fill:before {
  content: "\f24e";
}
.icon-toggle-fill:before {
  content: "\f24f";
}
.icon-dashboard-fill:before {
  content: "\f250";
}
.icon-refresh-fill:before {
  content: "\f251";
}
.icon-arrow-down-fill:before {
  content: "\f252";
}
.icon-upload-cloud-2-fill:before {
  content: "\f253";
}
.icon-arrow-drop-right-fill:before {
  content: "\f254";
}
.icon-eye-off-fill:before {
  content: "\f255";
}
.icon-function-fill:before {
  content: "\f256";
}
.icon-arrow-left-up-fill:before {
  content: "\f257";
}
.icon-thumb-up-fill:before {
  content: "\f258";
}
.icon-close-circle-fill:before {
  content: "\f259";
}
.icon-eye-fill:before {
  content: "\f25a";
}
.icon-arrow-go-forward-fill:before {
  content: "\f25b";
}
.icon-star-fill:before {
  content: "\f25c";
}
.icon-search-2-fill:before {
  content: "\f25d";
}
.icon-checkbox-blank-circle-fill:before {
  content: "\f25e";
}
.icon-menu-fill:before {
  content: "\f25f";
}
.icon-share-box-fill:before {
  content: "\f260";
}
.icon-shield-star-fill:before {
  content: "\f261";
}
.icon-delete-back-fill:before {
  content: "\f262";
}
.icon-arrow-go-back-fill:before {
  content: "\f263";
}
.icon-check-fill:before {
  content: "\f264";
}
.icon-radio-button-fill:before {
  content: "\f265";
}
.icon-arrow-right-s-fill:before {
  content: "\f266";
}
.icon-shield-keyhole-fill:before {
  content: "\f267";
}
.icon-arrow-left-right-fill:before {
  content: "\f268";
}
.icon-shield-cross-fill:before {
  content: "\f269";
}
.icon-download-2-fill:before {
  content: "\f26a";
}
.icon-alarm-fill:before {
  content: "\f26b";
}
.icon-settings-6-fill:before {
  content: "\f26c";
}
.icon-delete-bin-5-fill:before {
  content: "\f26d";
}
.icon-delete-bin-4-fill:before {
  content: "\f26e";
}
.icon-login-box-fill:before {
  content: "\f26f";
}
.icon-arrow-down-circle-fill:before {
  content: "\f270";
}
.icon-eye-close-fill:before {
  content: "\f271";
}
.icon-check-double-fill:before {
  content: "\f272";
}
.icon-star-half-s-fill:before {
  content: "\f273";
}
.icon-spam-2-fill:before {
  content: "\f274";
}
.icon-spam-3-fill:before {
  content: "\f275";
}
.icon-share-forward-fill:before {
  content: "\f276";
}
.icon-menu-add-fill:before {
  content: "\f277";
}
.icon-download-fill:before {
  content: "\f278";
}
.icon-more-fill:before {
  content: "\f279";
}
.icon-lock-password-fill:before {
  content: "\f27a";
}
.icon-checkbox-circle-fill:before {
  content: "\f27b";
}
.icon-menu-4-fill:before {
  content: "\f27c";
}
.icon-menu-5-fill:before {
  content: "\f27d";
}
.icon-shield-check-fill:before {
  content: "\f27e";
}
.icon-eye-2-fill:before {
  content: "\f27f";
}
.icon-external-link-fill:before {
  content: "\f280";
}
.icon-timer-2-fill:before {
  content: "\f281";
}
.icon-time-fill:before {
  content: "\f282";
}
.icon-zoom-out-fill:before {
  content: "\f283";
}
.icon-logout-box-r-fill:before {
  content: "\f284";
}
.icon-lock-unlock-fill:before {
  content: "\f285";
}
.icon-question-fill:before {
  content: "\f286";
}
.icon-close-fill:before {
  content: "\f287";
}
.icon-arrow-left-s-fill:before {
  content: "\f288";
}
.icon-arrow-left-down-fill:before {
  content: "\f289";
}
.icon-delete-bin-fill:before {
  content: "\f28a";
}
.icon-notification-badge-fill:before {
  content: "\f28b";
}
.icon-forbid-fill:before {
  content: "\f28c";
}
.icon-arrow-right-circle-fill:before {
  content: "\f28d";
}
.icon-arrow-drop-down-fill:before {
  content: "\f28e";
}
.icon-checkbox-indeterminate-fill:before {
  content: "\f28f";
}
.icon-shield-fill:before {
  content: "\f290";
}
.icon-loader-2-fill:before {
  content: "\f291";
}
.icon-account-circle-fill:before {
  content: "\f292";
}
.icon-user-shared-2-fill:before {
  content: "\f293";
}
.icon-user-location-fill:before {
  content: "\f294";
}
.icon-user-star-fill:before {
  content: "\f295";
}
.icon-robot-fill:before {
  content: "\f296";
}
.icon-emotion-2-fill:before {
  content: "\f297";
}
.icon-bear-smile-fill:before {
  content: "\f298";
}
.icon-admin-fill:before {
  content: "\f299";
}
.icon-ghost-fill:before {
  content: "\f29a";
}
.icon-body-scan-fill:before {
  content: "\f29b";
}
.icon-user-4-fill:before {
  content: "\f29c";
}
.icon-user-5-fill:before {
  content: "\f29d";
}
.icon-user-follow-fill:before {
  content: "\f29e";
}
.icon-user-received-fill:before {
  content: "\f29f";
}
.icon-women-fill:before {
  content: "\f2a0";
}
.icon-emotion-laugh-fill:before {
  content: "\f2a1";
}
.icon-user-unfollow-fill:before {
  content: "\f2a2";
}
.icon-user-3-fill:before {
  content: "\f2a3";
}
.icon-skull-2-fill:before {
  content: "\f2a4";
}
.icon-user-2-fill:before {
  content: "\f2a5";
}
.icon-travesti-fill:before {
  content: "\f2a6";
}
.icon-user-voice-fill:before {
  content: "\f2a7";
}
.icon-emotion-unhappy-fill:before {
  content: "\f2a8";
}
.icon-open-arm-fill:before {
  content: "\f2a9";
}
.icon-aliens-fill:before {
  content: "\f2aa";
}
.icon-spy-fill:before {
  content: "\f2ab";
}
.icon-account-pin-circle-fill:before {
  content: "\f2ac";
}
.icon-user-heart-fill:before {
  content: "\f2ad";
}
.icon-account-box-fill:before {
  content: "\f2ae";
}
.icon-user-shared-fill:before {
  content: "\f2af";
}
.icon-user-6-fill:before {
  content: "\f2b0";
}
.icon-ghost-2-fill:before {
  content: "\f2b1";
}
.icon-mickey-fill:before {
  content: "\f2b2";
}
.icon-skull-fill:before {
  content: "\f2b3";
}
.icon-emotion-fill:before {
  content: "\f2b4";
}
.icon-user-search-fill:before {
  content: "\f2b5";
}
.icon-user-add-fill:before {
  content: "\f2b6";
}
.icon-emotion-normal-fill:before {
  content: "\f2b7";
}
.icon-user-received-2-fill:before {
  content: "\f2b8";
}
.icon-emotion-happy-fill:before {
  content: "\f2b9";
}
.icon-contacts-fill:before {
  content: "\f2ba";
}
.icon-genderless-fill:before {
  content: "\f2bb";
}
.icon-men-fill:before {
  content: "\f2bc";
}
.icon-parent-fill:before {
  content: "\f2bd";
}
.icon-team-fill:before {
  content: "\f2be";
}
.icon-ghost-smile-fill:before {
  content: "\f2bf";
}
.icon-user-fill:before {
  content: "\f2c0";
}
.icon-star-smile-fill:before {
  content: "\f2c1";
}
.icon-group-fill:before {
  content: "\f2c2";
}
.icon-account-pin-box-fill:before {
  content: "\f2c3";
}
.icon-user-smile-fill:before {
  content: "\f2c4";
}
.icon-user-settings-fill:before {
  content: "\f2c5";
}
.icon-criminal-fill:before {
  content: "\f2c6";
}
.icon-emotion-sad-fill:before {
  content: "\f2c7";
}
.icon-group-2-fill:before {
  content: "\f2c8";
}
.icon-flashlight-fill:before {
  content: "\f2c9";
}
.icon-rainy-fill:before {
  content: "\f2ca";
}
.icon-celsius-fill:before {
  content: "\f2cb";
}
.icon-haze-2-fill:before {
  content: "\f2cc";
}
.icon-mist-fill:before {
  content: "\f2cd";
}
.icon-rainbow-fill:before {
  content: "\f2ce";
}
.icon-sun-fill:before {
  content: "\f2cf";
}
.icon-tornado-fill:before {
  content: "\f2d0";
}
.icon-haze-fill:before {
  content: "\f2d1";
}
.icon-windy-fill:before {
  content: "\f2d2";
}
.icon-moon-clear-fill:before {
  content: "\f2d3";
}
.icon-cloudy-fill:before {
  content: "\f2d4";
}
.icon-cloudy-2-fill:before {
  content: "\f2d5";
}
.icon-temp-hot-fill:before {
  content: "\f2d6";
}
.icon-showers-fill:before {
  content: "\f2d7";
}
.icon-typhoon-fill:before {
  content: "\f2d8";
}
.icon-fire-fill:before {
  content: "\f2d9";
}
.icon-thunderstorms-fill:before {
  content: "\f2da";
}
.icon-moon-cloudy-fill:before {
  content: "\f2db";
}
.icon-sun-foggy-fill:before {
  content: "\f2dc";
}
.icon-moon-fill:before {
  content: "\f2dd";
}
.icon-moon-foggy-fill:before {
  content: "\f2de";
}
.icon-hail-fill:before {
  content: "\f2df";
}
.icon-flood-fill:before {
  content: "\f2e0";
}
.icon-temp-cold-fill:before {
  content: "\f2e1";
}
.icon-heavy-showers-fill:before {
  content: "\f2e2";
}
.icon-meteor-fill:before {
  content: "\f2e3";
}
.icon-sun-cloudy-fill:before {
  content: "\f2e4";
}
.icon-fahrenheit-fill:before {
  content: "\f2e5";
}
.icon-drizzle-fill:before {
  content: "\f2e6";
}
.icon-foggy-fill:before {
  content: "\f2e7";
}
.icon-cloud-windy-fill:before {
  content: "\f2e8";
}
.icon-earthquake-fill:before {
  content: "\f2e9";
}
.icon-blaze-fill:before {
  content: "\f2ea";
}
.icon-snowy-fill:before {
  content: "\f2eb";
}
.icon-forbid-2-fill:before {
  content: "\f2ec";
}
.icon-loader-3-fill:before {
  content: "\f2ed";
}
.icon-indeterminate-circle-fill:before {
  content: "\f2ee";
}
.icon-filter-3-fill:before {
  content: "\f2ef";
}
.icon-filter-2-fill:before {
  content: "\f2f0";
}
.icon-share-forward-box-fill:before {
  content: "\f2f1";
}
.icon-lock-fill:before {
  content: "\f2f2";
}
.icon-alert-fill:before {
  content: "\f2f3";
}
.icon-star-half-fill:before {
  content: "\f2f4";
}
.icon-history-fill:before {
  content: "\f2f5";
}
.icon-thumb-down-fill:before {
  content: "\f2f6";
}
.icon-arrow-drop-up-fill:before {
  content: "\f2f7";
}
.icon-settings-3-fill:before {
  content: "\f2f8";
}
.icon-settings-2-fill:before {
  content: "\f2f9";
}
.icon-timer-flash-fill:before {
  content: "\f2fa";
}
.icon-apps-2-fill:before {
  content: "\f2fb";
}
.icon-list-settings-fill:before {
  content: "\f2fc";
}
.icon-checkbox-fill:before {
  content: "\f2fd";
}
.icon-subtract-fill:before {
  content: "\f2fe";
}
.icon-arrow-left-fill:before {
  content: "\f2ff";
}
.icon-shield-user-fill:before {
  content: "\f300";
}
.icon-arrow-right-up-fill:before {
  content: "\f301";
}
.icon-arrow-up-down-fill:before {
  content: "\f302";
}
.icon-alarm-warning-fill:before {
  content: "\f303";
}
.icon-loader-5-fill:before {
  content: "\f304";
}
.icon-loader-4-fill:before {
  content: "\f305";
}
.icon-information-fill:before {
  content: "\f306";
}
.icon-side-bar-fill:before {
  content: "\f307";
}
.icon-delete-back-2-fill:before {
  content: "\f308";
}
.icon-download-cloud-fill:before {
  content: "\f309";
}
.icon-arrow-up-s-fill:before {
  content: "\f30a";
}
.icon-arrow-left-circle-fill:before {
  content: "\f30b";
}
.icon-settings-4-fill:before {
  content: "\f30c";
}
.icon-settings-5-fill:before {
  content: "\f30d";
}
.icon-delete-bin-6-fill:before {
  content: "\f30e";
}
.icon-delete-bin-7-fill:before {
  content: "\f30f";
}
.icon-add-circle-fill:before {
  content: "\f310";
}
.icon-share-forward-2-fill:before {
  content: "\f311";
}
.icon-filter-fill:before {
  content: "\f312";
}
.icon-spam-fill:before {
  content: "\f313";
}
.icon-menu-fold-fill:before {
  content: "\f314";
}
.icon-more-2-fill:before {
  content: "\f315";
}
.icon-loader-fill:before {
  content: "\f316";
}
.icon-apps-fill:before {
  content: "\f317";
}
.icon-menu-unfold-fill:before {
  content: "\f318";
}
.icon-logout-circle-fill:before {
  content: "\f319";
}
.icon-arrow-up-circle-fill:before {
  content: "\f31a";
}
.icon-find-replace-fill:before {
  content: "\f31b";
}
.icon-checkbox-multiple-fill:before {
  content: "\f31c";
}
.icon-account-box-fill1:before {
  content: "\f31d";
}
.icon-account-circle-fill1:before {
  content: "\f31e";
}
.icon-account-pin-box-fill1:before {
  content: "\f31f";
}
.icon-account-pin-circle-fill1:before {
  content: "\f320";
}
.icon-admin-fill1:before {
  content: "\f321";
}
.icon-aliens-fill1:before {
  content: "\f322";
}
.icon-bear-smile-fill1:before {
  content: "\f323";
}
.icon-body-scan-fill1:before {
  content: "\f324";
}
.icon-contacts-fill1:before {
  content: "\f325";
}
.icon-criminal-fill1:before {
  content: "\f326";
}
.icon-emotion-2-fill1:before {
  content: "\f327";
}
.icon-emotion-fill1:before {
  content: "\f328";
}
.icon-emotion-happy-fill1:before {
  content: "\f329";
}
.icon-emotion-laugh-fill1:before {
  content: "\f32a";
}
.icon-emotion-normal-fill1:before {
  content: "\f32b";
}
.icon-emotion-sad-fill1:before {
  content: "\f32c";
}
.icon-emotion-unhappy-fill1:before {
  content: "\f32d";
}
.icon-genderless-fill1:before {
  content: "\f32e";
}
.icon-ghost-2-fill1:before {
  content: "\f32f";
}
.icon-ghost-fill1:before {
  content: "\f330";
}
.icon-ghost-smile-fill1:before {
  content: "\f331";
}
.icon-group-2-fill1:before {
  content: "\f332";
}
.icon-group-fill1:before {
  content: "\f333";
}
.icon-men-fill1:before {
  content: "\f334";
}
.icon-mickey-fill1:before {
  content: "\f335";
}
.icon-open-arm-fill1:before {
  content: "\f336";
}
.icon-parent-fill1:before {
  content: "\f337";
}
.icon-robot-fill1:before {
  content: "\f338";
}
.icon-skull-2-fill1:before {
  content: "\f339";
}
.icon-skull-fill1:before {
  content: "\f33a";
}
.icon-spy-fill1:before {
  content: "\f33b";
}
.icon-star-smile-fill1:before {
  content: "\f33c";
}
.icon-team-fill1:before {
  content: "\f33d";
}
.icon-travesti-fill1:before {
  content: "\f33e";
}
.icon-user-2-fill1:before {
  content: "\f33f";
}
.icon-user-3-fill1:before {
  content: "\f340";
}
.icon-user-4-fill1:before {
  content: "\f341";
}
.icon-user-5-fill1:before {
  content: "\f342";
}
.icon-user-6-fill1:before {
  content: "\f343";
}
.icon-user-add-fill1:before {
  content: "\f344";
}
.icon-user-fill1:before {
  content: "\f345";
}
.icon-user-follow-fill1:before {
  content: "\f346";
}
.icon-user-heart-fill1:before {
  content: "\f347";
}
.icon-user-location-fill1:before {
  content: "\f348";
}
.icon-user-received-2-fill1:before {
  content: "\f349";
}
.icon-user-received-fill1:before {
  content: "\f34a";
}
.icon-user-search-fill1:before {
  content: "\f34b";
}
.icon-user-settings-fill1:before {
  content: "\f34c";
}
.icon-user-shared-2-fill1:before {
  content: "\f34d";
}
.icon-user-shared-fill1:before {
  content: "\f34e";
}
.icon-user-smile-fill1:before {
  content: "\f34f";
}
.icon-user-star-fill1:before {
  content: "\f350";
}
.icon-user-unfollow-fill1:before {
  content: "\f351";
}
.icon-user-voice-fill1:before {
  content: "\f352";
}
.icon-women-fill1:before {
  content: "\f353";
}
.icon-add-box-fill1:before {
  content: "\f354";
}
.icon-add-circle-fill1:before {
  content: "\f355";
}
.icon-add-fill1:before {
  content: "\f356";
}
.icon-alarm-fill1:before {
  content: "\f357";
}
.icon-alarm-warning-fill1:before {
  content: "\f358";
}
.icon-alert-fill1:before {
  content: "\f359";
}
.icon-apps-2-fill1:before {
  content: "\f35a";
}
.icon-apps-fill1:before {
  content: "\f35b";
}
.icon-arrow-down-circle-fill1:before {
  content: "\f35c";
}
.icon-arrow-down-fill1:before {
  content: "\f35d";
}
.icon-arrow-down-s-fill1:before {
  content: "\f35e";
}
.icon-arrow-drop-down-fill1:before {
  content: "\f35f";
}
.icon-arrow-drop-left-fill1:before {
  content: "\f360";
}
.icon-arrow-drop-right-fill1:before {
  content: "\f361";
}
.icon-arrow-drop-up-fill1:before {
  content: "\f362";
}
.icon-arrow-go-back-fill1:before {
  content: "\f363";
}
.icon-arrow-go-forward-fill1:before {
  content: "\f364";
}
.icon-arrow-left-circle-fill1:before {
  content: "\f365";
}
.icon-arrow-left-down-fill1:before {
  content: "\f366";
}
.icon-arrow-left-fill1:before {
  content: "\f367";
}
.icon-arrow-left-right-fill1:before {
  content: "\f368";
}
.icon-arrow-left-s-fill1:before {
  content: "\f369";
}
.icon-arrow-left-up-fill1:before {
  content: "\f36a";
}
.icon-arrow-right-circle-fill1:before {
  content: "\f36b";
}
.icon-arrow-right-down-fill1:before {
  content: "\f36c";
}
.icon-arrow-right-fill1:before {
  content: "\f36d";
}
.icon-arrow-right-s-fill1:before {
  content: "\f36e";
}
.icon-arrow-right-up-fill1:before {
  content: "\f36f";
}
.icon-arrow-up-circle-fill1:before {
  content: "\f370";
}
.icon-arrow-up-down-fill1:before {
  content: "\f371";
}
.icon-arrow-up-fill1:before {
  content: "\f372";
}
.icon-arrow-up-s-fill1:before {
  content: "\f373";
}
.icon-check-double-fill1:before {
  content: "\f374";
}
.icon-check-fill1:before {
  content: "\f375";
}
.icon-checkbox-blank-circle-fill1:before {
  content: "\f376";
}
.icon-checkbox-blank-fill1:before {
  content: "\f377";
}
.icon-checkbox-circle-fill1:before {
  content: "\f378";
}
.icon-checkbox-fill1:before {
  content: "\f379";
}
.icon-checkbox-indeterminate-fill1:before {
  content: "\f37a";
}
.icon-checkbox-multiple-blank-fill1:before {
  content: "\f37b";
}
.icon-checkbox-multiple-fill1:before {
  content: "\f37c";
}
.icon-close-circle-fill1:before {
  content: "\f37d";
}
.icon-close-fill1:before {
  content: "\f37e";
}
.icon-dashboard-fill1:before {
  content: "\f37f";
}
.icon-delete-back-2-fill1:before {
  content: "\f380";
}
.icon-delete-back-fill1:before {
  content: "\f381";
}
.icon-delete-bin-2-fill1:before {
  content: "\f382";
}
.icon-delete-bin-3-fill1:before {
  content: "\f383";
}
.icon-delete-bin-4-fill1:before {
  content: "\f384";
}
.icon-delete-bin-5-fill1:before {
  content: "\f385";
}
.icon-delete-bin-6-fill1:before {
  content: "\f386";
}
.icon-delete-bin-7-fill1:before {
  content: "\f387";
}
.icon-delete-bin-fill1:before {
  content: "\f388";
}
.icon-divide-fill1:before {
  content: "\f389";
}
.icon-download-2-fill1:before {
  content: "\f38a";
}
.icon-download-cloud-2-fill1:before {
  content: "\f38b";
}
.icon-download-cloud-fill1:before {
  content: "\f38c";
}
.icon-download-fill1:before {
  content: "\f38d";
}
.icon-error-warning-fill1:before {
  content: "\f38e";
}
.icon-external-link-fill1:before {
  content: "\f38f";
}
.icon-eye-2-fill1:before {
  content: "\f390";
}
.icon-eye-close-fill1:before {
  content: "\f391";
}
.icon-eye-fill1:before {
  content: "\f392";
}
.icon-eye-off-fill1:before {
  content: "\f393";
}
.icon-filter-2-fill1:before {
  content: "\f394";
}
.icon-filter-3-fill1:before {
  content: "\f395";
}
.icon-filter-fill1:before {
  content: "\f396";
}
.icon-filter-off-fill1:before {
  content: "\f397";
}
.icon-find-replace-fill1:before {
  content: "\f398";
}
.icon-forbid-2-fill1:before {
  content: "\f399";
}
.icon-forbid-fill1:before {
  content: "\f39a";
}
.icon-function-fill1:before {
  content: "\f39b";
}
.icon-history-fill1:before {
  content: "\f39c";
}
.icon-indeterminate-circle-fill1:before {
  content: "\f39d";
}
.icon-information-fill1:before {
  content: "\f39e";
}
.icon-list-settings-fill1:before {
  content: "\f39f";
}
.icon-loader-2-fill1:before {
  content: "\f3a0";
}
.icon-loader-3-fill1:before {
  content: "\f3a1";
}
.icon-loader-4-fill1:before {
  content: "\f3a2";
}
.icon-loader-5-fill1:before {
  content: "\f3a3";
}
.icon-loader-fill1:before {
  content: "\f3a4";
}
.icon-lock-2-fill1:before {
  content: "\f3a5";
}
.icon-lock-fill1:before {
  content: "\f3a6";
}
.icon-lock-password-fill1:before {
  content: "\f3a7";
}
.icon-lock-unlock-fill1:before {
  content: "\f3a8";
}
.icon-login-box-fill1:before {
  content: "\f3a9";
}
.icon-login-circle-fill1:before {
  content: "\f3aa";
}
.icon-logout-box-fill1:before {
  content: "\f3ab";
}
.icon-logout-box-r-fill1:before {
  content: "\f3ac";
}
.icon-logout-circle-fill1:before {
  content: "\f3ad";
}
.icon-logout-circle-r-fill1:before {
  content: "\f3ae";
}
.icon-menu-2-fill1:before {
  content: "\f3af";
}
.icon-menu-3-fill1:before {
  content: "\f3b0";
}
.icon-menu-4-fill1:before {
  content: "\f3b1";
}
.icon-menu-5-fill1:before {
  content: "\f3b2";
}
.icon-menu-add-fill1:before {
  content: "\f3b3";
}
.icon-menu-fill1:before {
  content: "\f3b4";
}
.icon-menu-fold-fill1:before {
  content: "\f3b5";
}
.icon-menu-unfold-fill1:before {
  content: "\f3b6";
}
.icon-more-2-fill1:before {
  content: "\f3b7";
}
.icon-more-fill1:before {
  content: "\f3b8";
}
.icon-notification-badge-fill1:before {
  content: "\f3b9";
}
.icon-question-fill1:before {
  content: "\f3ba";
}
.icon-radio-button-fill1:before {
  content: "\f3bb";
}
.icon-refresh-fill1:before {
  content: "\f3bc";
}
.icon-search-2-fill1:before {
  content: "\f3bd";
}
.icon-search-eye-fill1:before {
  content: "\f3be";
}
.icon-search-fill1:before {
  content: "\f3bf";
}
.icon-settings-2-fill1:before {
  content: "\f3c0";
}
.icon-settings-3-fill1:before {
  content: "\f3c1";
}
.icon-settings-4-fill1:before {
  content: "\f3c2";
}
.icon-settings-5-fill1:before {
  content: "\f3c3";
}
.icon-settings-6-fill1:before {
  content: "\f3c4";
}
.icon-settings-fill1:before {
  content: "\f3c5";
}
.icon-share-box-fill1:before {
  content: "\f3c6";
}
.icon-share-circle-fill1:before {
  content: "\f3c7";
}
.icon-share-fill1:before {
  content: "\f3c8";
}
.icon-share-forward-2-fill1:before {
  content: "\f3c9";
}
.icon-share-forward-box-fill1:before {
  content: "\f3ca";
}
.icon-share-forward-fill1:before {
  content: "\f3cb";
}
.icon-shield-check-fill1:before {
  content: "\f3cc";
}
.icon-shield-cross-fill1:before {
  content: "\f3cd";
}
.icon-shield-fill1:before {
  content: "\f3ce";
}
.icon-shield-flash-fill1:before {
  content: "\f3cf";
}
.icon-shield-keyhole-fill1:before {
  content: "\f3d0";
}
.icon-shield-star-fill1:before {
  content: "\f3d1";
}
.icon-shield-user-fill1:before {
  content: "\f3d2";
}
.icon-side-bar-fill1:before {
  content: "\f3d3";
}
.icon-spam-2-fill1:before {
  content: "\f3d4";
}
.icon-spam-3-fill1:before {
  content: "\f3d5";
}
.icon-spam-fill1:before {
  content: "\f3d6";
}
.icon-star-fill1:before {
  content: "\f3d7";
}
.icon-star-half-fill1:before {
  content: "\f3d8";
}
.icon-star-half-s-fill1:before {
  content: "\f3d9";
}
.icon-star-s-fill1:before {
  content: "\f3da";
}
.icon-subtract-fill1:before {
  content: "\f3db";
}
.icon-thumb-down-fill1:before {
  content: "\f3dc";
}
.icon-thumb-up-fill1:before {
  content: "\f3dd";
}
.icon-time-fill1:before {
  content: "\f3de";
}
.icon-timer-2-fill1:before {
  content: "\f3df";
}
.icon-timer-fill1:before {
  content: "\f3e0";
}
.icon-timer-flash-fill1:before {
  content: "\f3e1";
}
.icon-toggle-fill1:before {
  content: "\f3e2";
}
.icon-upload-2-fill1:before {
  content: "\f3e3";
}
.icon-upload-cloud-2-fill1:before {
  content: "\f3e4";
}
.icon-upload-cloud-fill1:before {
  content: "\f3e5";
}
.icon-upload-fill1:before {
  content: "\f3e6";
}
.icon-zoom-in-fill1:before {
  content: "\f3e7";
}
.icon-zoom-out-fill1:before {
  content: "\f3e8";
}
